import { Tab } from '@garmin-avcloud/avcloud-fly-web-common/shared';

type RouteLessTab = Omit<Tab, 'route'>;

export const ACTIVE_SUBSCRIPTIONS_TAB: RouteLessTab = {
  displayText: 'active subscriptions',
  isActiveMatchOptions: {
    matrixParams: 'exact',
    queryParams: 'exact',
    paths: 'subset',
    fragment: 'exact'
  }
};

export const EXPIRED_SUBSCRIPTIONS_TAB: RouteLessTab = {
  displayText: 'expired subscriptions'
};

export const MANAGE_MOBILE_DEVICES_TAB: RouteLessTab = {
  displayText: 'manage mobile devices',
  isActiveMatchOptions: {
    matrixParams: 'exact',
    queryParams: 'exact',
    paths: 'subset',
    fragment: 'exact'
  }
};
