import { Component, Input, OnInit } from '@angular/core';
import { CartItemType } from '@garmin-avcloud/avcloud-fly-web-common/api';
import { CartAddItemRequest } from '../../../../shared/models/cart/cart-add-item-request';
import { Cart } from '../../../../shared/models/cart/cart.model';
import { GarminPilotSubscriptionCard } from '../../../../shared/models/subscriptions/garmin-pilot-subscription-card.model';

@Component({
  selector: 'fly-garmin-pilot-subscription-card-expired',
  templateUrl: './garmin-pilot-subscription-card-expired.component.html',
  styleUrls: ['../subscription-card/subscription-card.component.scss'],
  standalone: false
})
export class GarminPilotSubscriptionCardExpiredComponent implements OnInit {
  @Input({ required: true }) cardData: GarminPilotSubscriptionCard;
  @Input() currentCart?: Cart;
  presentInCurrentCart: boolean = false;
  addToCurrentCartFailed: boolean | null = null;
  itemForAddingToCurrentCart: CartAddItemRequest;

  ngOnInit(): void {
    this.createItemForAddingToCurrentCart();
    this.presentInCurrentCart =
      this.currentCart == null
        ? false
        : this.currentCart?.gpCart.some((item) => item.product.partNumber === this.cardData.product.sku);
  }

  private createItemForAddingToCurrentCart(): void {
    this.itemForAddingToCurrentCart = {
      itemType: CartItemType.GARMIN_PILOT,
      partNumber: this.cardData.subscriptionData.sku,
      subscriptionID: undefined,
      deviceIDs: [],
      autoRenew: false
    };
  }
}
