@switch (currentState()) {
  @case ('LOADED') {
    <button (click)="navigateToMobileDeviceList()"> 🠔 Return to Mobile Devices List </button>
    <fly-manage-mobile-device
      [deviceRegistrationUuid]="deviceRegistrationUuid"
      (deviceDeleted)="location.back()">
    </fly-manage-mobile-device>
    <fly-mobile-device-subscriptions-list [deviceRegistrationUuid]="deviceRegistrationUuid">
    </fly-mobile-device-subscriptions-list>
  }
  @case ('LOADING') {
    <fly-full-page-loader></fly-full-page-loader>
  }
}
