@if (!presentInCurrentCart && !addingToCurrentCart) {
  <g-button
    [theme]="'dark'"
    (click)="addToCart()"
    >Add to Cart
  </g-button>
}
@if (addingToCurrentCart) {
  <g-loader
    type="dark"
    class="expired-loader">
  </g-loader>
}
@if (presentInCurrentCart) {
  <g-button
    type="light"
    (click)="navigateToCartPage()"
    >In Cart</g-button
  >
}
