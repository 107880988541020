import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  GarminPilotDeviceRegistration,
  GarminPilotDeviceRegistrationsControllerClient
} from '@garmin-avcloud/avcloud-fly-web-common/api';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GpDeviceRegistrationsClientService implements GarminPilotDeviceRegistrationsControllerClient {
  private readonly httpClient = inject(HttpClient);
  private readonly jsonHttpOptions = {
    headers: new HttpHeaders({
      Accept: 'application/json',
      'X-garmin-client-id': 'FLY_GARMIN_APP'
    })
  };
  private readonly url = (mode: 'Read' | 'Write'): string => {
    return `${environment.coreDataHostUrl}/customer${mode}Service/device-registrations/gp`;
  };

  getGarminPilotDeviceRegistration(
    deviceRegistrationUuid: string,
    includeDeleted?: boolean
  ): Observable<GarminPilotDeviceRegistration> {
    return this.httpClient.get<GarminPilotDeviceRegistration>(`${this.url('Read')}/${deviceRegistrationUuid}`, {
      ...this.jsonHttpOptions,
      params: new HttpParams().set('includeDeleted', includeDeleted ?? false)
    });
  }

  getGarminPilotDeviceRegistrations(
    requestBody: Partial<GarminPilotDeviceRegistration>,
    includeDeleted?: boolean
  ): Observable<GarminPilotDeviceRegistration[]> {
    return this.httpClient.post<GarminPilotDeviceRegistration[]>(`${this.url('Read')}/search`, requestBody, {
      ...this.jsonHttpOptions,
      params: new HttpParams().set('includeDeleted', includeDeleted ?? false)
    });
  }

  deleteGarminPilotDeviceRegistrations(deviceRegistrationUuid: string): Observable<HttpResponse<any>> {
    return this.httpClient.delete(`${this.url('Write')}/${deviceRegistrationUuid}`, {
      ...this.jsonHttpOptions,
      observe: 'response'
    });
  }

  updateGarminPilotDeviceRegistrations(
    deviceRegistrationUuid: string,
    requestBody: Partial<Omit<GarminPilotDeviceRegistration, 'uuid' | 'customerGuid' | 'gpDeviceCode' | 'msId' | 'pin'>>
  ): Observable<GarminPilotDeviceRegistration> {
    return this.httpClient.patch<GarminPilotDeviceRegistration>(
      `${this.url('Write')}/${deviceRegistrationUuid}`,
      requestBody,
      { ...this.jsonHttpOptions }
    );
  }
}
