import { Injectable } from '@angular/core';
import { AbstractAvdbSubscriptionsService, SubscriptionResponse } from '@garmin-avcloud/avcloud-fly-web-common/api';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AvdbBundleRedemptionRequest } from '../models/subscriptions/avdb-bundle-redemption-request.model';

@Injectable({
  providedIn: 'root'
})
export class AvdbSubscriptionsService extends AbstractAvdbSubscriptionsService {
  protected readonly baseUrl = `${environment.flyLegacyUrl}/api/subscriptions/avdb`;

  redeemBundle(payload: AvdbBundleRedemptionRequest): Observable<SubscriptionResponse> {
    return this.http.post<SubscriptionResponse>(`${this.baseUrl}/redeem`, payload);
  }
}
