import { HttpErrorResponse } from '@angular/common/http';
import { computed, DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { PendingSubscriptionJob, SubscriptionJobManagement } from '@garmin-avcloud/avcloud-fly-web-common/api';
import {
  catchError,
  combineLatest,
  filter,
  interval,
  map,
  merge,
  Observable,
  of,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
  throwError
} from 'rxjs';

import { BillingAccountService } from '../../features/subscription-management/services/billing-account.service';
import { nullAndUndefinedFilter } from '../utilities/null-and-undefined-filter';
import { distinctUntilChangedJSONCheck } from '../utilities/rxjs-helpers';
import { ConnextClientService } from './connext-client.service';
import { PendingSubscriptionJobService } from './pending-subscription-job.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionJobManagementService implements SubscriptionJobManagement {
  private readonly connextClientService = inject(ConnextClientService);
  private readonly billingAccountService = inject(BillingAccountService);
  private readonly pendingSubscriptionJobService = inject(PendingSubscriptionJobService);
  private readonly refresh$ = new Subject<void>();
  private destroy$ = new Subject<void>();
  serviceStatus = computed(() => (this.currentContext() == null ? 'LOADING' : 'LOADED'));
  currentContext = toSignal(
    toObservable(this.billingAccountService.currentBillingAccount).pipe(
      tap(() => {
        this.destroy$.next();
        this.destroy$ = new Subject<void>();
      }),
      filter(nullAndUndefinedFilter),
      map((billingAccount) =>
        merge(interval(3000), this.refresh$).pipe(
          takeUntil(this.destroy$),
          startWith(0),
          switchMap(() =>
            this.pendingSubscriptionJobService.getPendingSubscriptionJobs({
              billingAccountId: billingAccount.billingAccountId
            })
          ),
          shareReplay({ bufferSize: 1, refCount: true }) // Auto-unsubscribes when no subscribers
        )
      )
    )
  );

  createPendingSubscriptionJob(requestBody: PendingSubscriptionJob): Observable<PendingSubscriptionJob> {
    return this.pendingSubscriptionJobService
      .createPendingSubscriptionJob(requestBody)
      .pipe(tap(() => this.refresh$.next()));
  }

  /**
   * Using the common observable initialized from {@linkcode initQueryToMonitorWith}, check to see if there is an active {@link PendingSubscriptionJob}
   * for the given sku.
   *
   * If there is no reported job in Fly Land, then does not emit anything until a job has been found in the common observable.
   *
   * If a job for the given sku was found in the common observable, then this observable also checks if the active job is "legit".
   * It periodically checks with yarmouth the state of the correlating job. If the state of the job is not "complete", then emits the {@link PendingSubscriptionJob}
   * as the job is legit.
   *
   * As mentioned in the line above, this observable will occasionally check in with yarmouth to see if yarmouth has "completed" the job.
   * Upon completion from yarmouth, this observable handles the cleanup of the correlating {@link PendingSubscriptionJob} in Fly Land.
   * In this case, the emitted value will be `null` as the job is no longer legit, and will force a refresh of the common observable.
   *
   * @param sku The sku to monitor for active jobs.
   * @param destroyRef Reference to check if the observable listener has been destroyed or not.
   *  There is no reason to have this observable be firing if nothing is listening.
   * @returns An observable which will return a {@link PendingSubscriptionJob} if there is indeed an active job for the provided sku. Null otherwise.
   */
  listenForJobsBySku(sku: string, destroyRef: DestroyRef): Observable<PendingSubscriptionJob | null> {
    return this.listenForJobsBy(sku, 'sku', destroyRef).pipe(
      map((pendingSubscriptionJob) => {
        if (Array.isArray(pendingSubscriptionJob)) {
          throw new Error('listenForJobsBySku should not return an array.');
        }
        return pendingSubscriptionJob;
      })
    );
  }

  listenForJobsByActionType(actionType: string, destroyRef?: DestroyRef): Observable<PendingSubscriptionJob[] | null> {
    return this.listenForJobsBy(actionType, 'actionType', destroyRef).pipe(
      map((pendingSubscriptionJobs) => {
        if (!Array.isArray(pendingSubscriptionJobs) && pendingSubscriptionJobs !== null) {
          throw new Error('listenForJobsByActionType should return an array or null.');
        }
        return pendingSubscriptionJobs;
      })
    );
  }

  listenForJobsBySubscriptionUuid(
    subscriptionUuid: string,
    destroyRef: DestroyRef
  ): Observable<PendingSubscriptionJob | null> {
    return this.listenForJobsBy(subscriptionUuid, 'subscriptionUuid', destroyRef).pipe(
      map((pendingSubscriptionJob) => {
        if (Array.isArray(pendingSubscriptionJob)) {
          throw new Error('listenForJobsBySubscriptionUuid should not return an array.');
        }
        return pendingSubscriptionJob;
      })
    );
  }

  private listenForJobsBy(
    searchValue: string,
    searchBy: 'sku' | 'subscriptionUuid' | 'actionType',
    destroyRef?: DestroyRef
  ): Observable<PendingSubscriptionJob | PendingSubscriptionJob[] | null> {
    let currentContext = this.currentContext();
    if (currentContext == null) {
      //Should never be hit.
      throw new Error(
        'Listen method was called when no context was initialized. Did you check whether serviceStatus() is LOADED?'
      );
    }

    currentContext = currentContext.pipe(distinctUntilChangedJSONCheck);

    if (destroyRef != null) {
      currentContext = currentContext.pipe(takeUntilDestroyed(destroyRef));
    }

    return currentContext.pipe(
      map((pendingJobs: PendingSubscriptionJob[]): PendingSubscriptionJob | PendingSubscriptionJob[] | null => {
        if (searchBy === 'sku') {
          return pendingJobs.find((job) => job.sku === searchValue) ?? null;
        } else if (searchBy === 'subscriptionUuid') {
          return pendingJobs.find((job) => job.subscriptionUuid === searchValue) ?? null;
        } else if (searchBy === 'actionType') {
          return pendingJobs.filter((job) => job.subscriptionActionType.name === searchValue);
        } else {
          return null;
        }
      }),
      switchMap((jobsOrJob) => {
        if (jobsOrJob == null) return of(null);

        if (Array.isArray(jobsOrJob)) {
          if (jobsOrJob.length === 0) {
            return of([]);
          }

          return combineLatest(jobsOrJob.map((job) => this.handleJob(job))).pipe(
            map((jobs) => {
              const filteredJobs = jobs.filter((job) => job !== null);
              return filteredJobs.length > 0 ? filteredJobs : [];
            })
          );
        } else {
          return this.handleJob(jobsOrJob);
        }
      })
    );
  }

  private handleJob(pendingJob: PendingSubscriptionJob): Observable<PendingSubscriptionJob | null> {
    return interval(5000).pipe(
      startWith(0),
      switchMap(() => this.connextClientService.getActivationSubscriptionJobStatus(pendingJob.statusJobUuid)),
      distinctUntilChangedJSONCheck,
      filter(nullAndUndefinedFilter),
      switchMap((jobResponse) => {
        if (jobResponse.statusText === 'Complete') {
          return this.pendingSubscriptionJobService.deletePendingSubscriptionsJob(pendingJob.id!).pipe(
            catchError((error: HttpErrorResponse) => {
              if (error.status === 404) {
                // if the job was there before but now not findable, it's probably because it's deleted by FlyAdmin already.
                return of(null);
              } else {
                return throwError(() => error);
              }
            }),
            tap(() => this.refresh$.next()),
            map(() => null)
          );
        } else if (jobResponse.statusText === 'FatalError') {
          return of(pendingJob);
        } else {
          return of(pendingJob);
        }
      })
    );
  }
}
