import { Component, computed, EventEmitter, inject, input, Output, ViewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import {
  isAvdbSubscription,
  isGarminPilotSubscription,
  SubscriptionActionType
} from '@garmin-avcloud/avcloud-fly-web-common/api';
import {
  ComponentsGlobalWebModule,
  FeatureFlyGarmin,
  FormattedDatePipe,
  ModalComponent
} from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { FeaturesService } from '@garmin-avcloud/avcloud-web-utils/feature';
import { concatMap, EMPTY, filter, forkJoin, of, switchMap } from 'rxjs';
import { UserConfigService } from '../../../../core/services/config/user-config.service';
import { isAvdbSubscriptionCard } from '../../../../shared/models/subscriptions/avdb-subscription-card.model';
import { SubscriptionActionTypeMap } from '../../../../shared/models/subscriptions/subscription-action-type-map';
import { SubscriptionCard } from '../../../../shared/models/subscriptions/subscription-card.model';
import { SubscriptionJobTypeMap } from '../../../../shared/models/subscriptions/subscription-job-type-map';
import { UpdateAutoRenewRequest } from '../../../../shared/models/subscriptions/update-auto-renew-request.model';
import { AccountDisplayPipe } from '../../../../shared/pipes/account-display.pipe';
import { PendingSubscriptionJobService } from '../../../../shared/services/pending-subscription-job.service';
import { SubscriptionsGeneralService } from '../../../../shared/services/subscriptions-general.service';
import { BillingAccountSelectionComponent } from '../../../purchasing/cart/components/billing-account-selection/billing-account-selection.component';
import { BillingAccountService } from '../../services/billing-account.service';

@Component({
  selector: 'fly-auto-renewal-modal',
  imports: [
    ModalComponent,
    AccountDisplayPipe,
    FormattedDatePipe,
    BillingAccountSelectionComponent,
    ComponentsGlobalWebModule
  ],
  templateUrl: './auto-renewal-modal.component.html',
  styleUrls: ['./auto-renewal-modal.component.scss']
})
export class AutoRenewalModalComponent {
  readonly toggleData = input.required<SubscriptionCard>();
  readonly autorenewalEnabled = input.required<boolean | null>();
  readonly toggleControl = input<FormControl<boolean> | null>(null);
  @Output() readonly autoRenewalUpdateRequestSucceeded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() readonly subscriptionJobType: EventEmitter<SubscriptionActionType> =
    new EventEmitter<SubscriptionActionType>();
  @ViewChild(ModalComponent) modal: ModalComponent;
  formGroup = new FormGroup({});

  protected readonly billingAccountService = inject(BillingAccountService);
  protected readonly userConfigService = inject(UserConfigService);
  private readonly userConfig = toSignal(this.userConfigService.getUserConfig());
  protected readonly originalBillingAccount = computed(() =>
    this.billingAccountService.getBillingAccountByBillingAccountId(this.toggleData().subscriptionData.billingAccountId)
  );
  private readonly featuresService = inject(FeaturesService);
  readonly promoCreditsTermExtensionsFeatureIsActive = toSignal(
    this.featuresService.isFeatureActive(FeatureFlyGarmin.PROMO_CREDITS_TERM_EXTENSIONS),
    {
      initialValue: false
    }
  );
  private readonly subscriptionsGeneralService = inject(SubscriptionsGeneralService);
  private readonly pendingSubscriptionJobService = inject(PendingSubscriptionJobService);
  private readonly billingAccountIdSelection = toSignal(
    this.formGroup.valueChanges.pipe(
      filter(() => this.formGroup.get('billingAccountSelect') != null),
      switchMap(() => {
        const selectedBillingAccountId = this.formGroup.get('billingAccountSelect');
        return selectedBillingAccountId != null ? selectedBillingAccountId.valueChanges : EMPTY;
      })
    )
  );
  private readonly newBillingAccountId = computed(() => {
    if (!this.promoCreditsTermExtensionsFeatureIsActive()) {
      return null;
    }

    if (this.originalBillingAccount()?.isNonBillingAccount === true) {
      if (this.billingAccountService.realBillingAccounts().length === 1) {
        return this.billingAccountService.realBillingAccounts()[0].billingAccountId;
      } else {
        return this.billingAccountIdSelection();
      }
    } else {
      return null;
    }
  });
  protected readonly modalConfirmShouldDisable = computed(() => {
    if (!this.promoCreditsTermExtensionsFeatureIsActive()) {
      return false;
    }

    if (this.originalBillingAccount()?.isNonBillingAccount === true) {
      return this.newBillingAccountId() == null;
    } else {
      return false;
    }
  });

  open(): void {
    this.modal.open();
  }

  protected modalConfirm = (): void => {
    this.modal.close();

    // Disable the toggle while processing
    const toggleControl = this.toggleControl();
    if (toggleControl != null) {
      toggleControl.disable();
    }

    const toggleData = this.toggleData();

    const deviceId = isAvdbSubscriptionCard(toggleData)
      ? toggleData.coveredAircraftSystems?.[0]?.legacyDeviceId
      : undefined;

    const payload: UpdateAutoRenewRequest = {
      transactionUuid: toggleData.transactionUuid,
      subscriptionUuid: toggleData.subscriptionData.subscriptionUuid,
      autoRenew: !(this.autorenewalEnabled() ?? false)
    };

    if (this.promoCreditsTermExtensionsFeatureIsActive()) {
      const newBillingAccountId = this.newBillingAccountId();
      if (newBillingAccountId != null) {
        payload.billingAccountId = newBillingAccountId;
      }
    }

    this.subscriptionsGeneralService
      .updateAutoRenew(payload)
      .pipe(
        concatMap((response) =>
          forkJoin({
            response: of(response),
            jobCreation: this.pendingSubscriptionJobService.createPendingSubscriptionJob({
              sku: toggleData.subscriptionData.sku,
              subscriptionJobType: isGarminPilotSubscription(toggleData.subscriptionData)
                ? SubscriptionJobTypeMap.PILOT
                : SubscriptionJobTypeMap.AVDB,
              subscriptionActionType: !(this.autorenewalEnabled() ?? false)
                ? SubscriptionActionTypeMap.ENABLE_AUTO_RENEW
                : SubscriptionActionTypeMap.DISABLE_AUTO_RENEW,
              statusJobUuid: response.jobUuid,
              subscriptionUuid: toggleData.subscriptionData.subscriptionUuid,
              billingAccountId: toggleData.subscriptionData.billingAccountId,
              customerGuid: this.userConfig()?.loggedInUserCustomerGuid ?? '',
              yarmouthDeviceId: deviceId?.toString(),
              masterAircraftUuid: isAvdbSubscription(toggleData.subscriptionData)
                ? (toggleData.subscriptionData.aircraftUuid ?? undefined)
                : undefined
            })
          })
        )
      )
      .subscribe({
        next: ({ response, jobCreation }) => {
          if (response.success) {
            this.subscriptionJobType.emit(jobCreation.subscriptionActionType);
            this.autoRenewalUpdateRequestSucceeded.emit(true);
          } else {
            this.autoRenewalUpdateRequestSucceeded.emit(false);
          }
        },
        error: () => this.autoRenewalUpdateRequestSucceeded.emit(false)
      });
  };
}
