import { Pipe, PipeTransform } from '@angular/core';
import { MobileDeviceIdentifier } from '../../../shared/models/subscriptions/garmin-pilot-subscription-card.model';

@Pipe({
  name: 'flyMobileDeviceNames',
  standalone: false
})
export class MobileDeviceNamesPipe implements PipeTransform {
  transform(deviceIdentifiers: MobileDeviceIdentifier[]): string {
    return deviceIdentifiers.map((identifier) => identifier.deviceName).join(', ');
  }
}
