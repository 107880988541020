import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { MobileDevice } from '../../../../shared/models/mobile-device/mobile-device.model';
import { MobileDeviceListService } from '../../services/mobile-device-list.service';

enum State {
  LOADING,
  LOADED,
  ERROR
}

@Component({
  selector: 'fly-mobile-devices-list',
  templateUrl: './mobile-devices-list.component.html',
  styleUrls: ['./mobile-devices-list.component.scss'],
  standalone: false
})
export class MobileDevicesListComponent implements OnInit {
  mobileDevices: MobileDevice[] = [];
  currentState: State = State.LOADING;
  readonly State = State;
  protected isWebview: boolean = false;
  private readonly mobileDeviceListService = inject(MobileDeviceListService);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.isWebview = this.activatedRoute.snapshot.data.isWebview;
    this.mobileDeviceListService
      .getMobileDevices()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (mobileDevices: MobileDevice[]) => {
          this.mobileDevices = mobileDevices;
          this.currentState = State.LOADED;
        },
        error: () => (this.currentState = State.ERROR)
      });
  }

  navigateToDeviceDetails(deviceRegistrationUuid: string | null): void {
    if (deviceRegistrationUuid == null) {
      throw new Error('Device has an invalid uuid.');
    }

    this.router.navigate([deviceRegistrationUuid], { relativeTo: this.activatedRoute });
  }
}
