<fly-modal
  #modal
  [header]="'CANCEL SUBSCRIPTION?'"
  [buttons]="[
    {
      text: 'Close',
      action: 'close'
    },
    {
      text: 'Cancel Subscription',
      primary: true,
      action: modalConfirm
    }
  ]">
  <div slot="body">
    <div class="content">
      <p>
        By cancelling this subscription, Auto-renew will be turned off and your subscription will expire on the date
        noted.
      </p>
    </div>
  </div>
</fly-modal>
