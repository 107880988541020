@if (earlyRenewalEnabled) {
  @if (!presentInCurrentCart && !addingToCurrentCart) {
    <g-button
      [theme]="cardData.subscriptionData.autorenewalEnabled ? 'light' : 'dark'"
      (click)="addToCart()">
      {{ cardData.subscriptionData.renewable ? 'Renew' : 'Extend' }}
    </g-button>
  }
  @if (addingToCurrentCart) {
    <g-loader type="dark"> </g-loader>
  }
  @if (presentInCurrentCart) {
    <g-button
      type="light"
      (click)="navigateToCartPage()">
      {{ cardData.subscriptionData.renewable ? 'Renewal' : 'Extension' }} Added to Cart
    </g-button>
  }
}
