// checks if a subscription's start date is in the future from now
export function isFutureStartDate(futureStartDate?: string | null): boolean {
  if (futureStartDate == null) {
    return false;
  }

  const startDate = new Date(futureStartDate);
  const currentDate = new Date();

  return startDate > currentDate;
}
