import { Injectable } from '@angular/core';
import { GpSubscriptionProductType } from '../../../shared/enums/gp-subscription-type.enum';
import { GarminPilotSubscriptionCard } from '../../../shared/models/subscriptions/garmin-pilot-subscription-card.model';
import { SubscriptionCardsFilterAndSortServiceAbstract } from '../abstracts/subscription-cards-filter-and-sort-service.abstract';
import { FilterName } from '../enums/filter-name.enum';

@Injectable({
  providedIn: 'root'
})
export class GpSubscriptionCardsFilterAndSortService extends SubscriptionCardsFilterAndSortServiceAbstract<GarminPilotSubscriptionCard> {
  protected filterSubscriptionCards(gpSubscriptionCards: GarminPilotSubscriptionCard[]): GarminPilotSubscriptionCard[] {
    return gpSubscriptionCards
      .filter((gpSubscriptionCard) => {
        const filteredRegions = this.filters.get(FilterName.REGION) ?? [];
        if (filteredRegions.length == 0) {
          return true;
        }
        const gpSubscriptionCardRegions: string[] = Object.values(gpSubscriptionCard.product.regions ?? {});
        return filteredRegions.some((region) => gpSubscriptionCardRegions.includes(region));
      })
      .filter((gpSubscriptionCard) => {
        const filteredProductTypes = this.filters.get(FilterName.PRODUCT_TYPE) ?? [];
        if (filteredProductTypes.length == 0) {
          return true;
        }
        if (gpSubscriptionCard.product.isAddon == null) {
          return true;
        }
        if (gpSubscriptionCard.product.isAddon) {
          return this.filters.get(FilterName.PRODUCT_TYPE)?.includes(GpSubscriptionProductType.ADD_ON);
        } else {
          return this.filters.get(FilterName.PRODUCT_TYPE)?.includes(GpSubscriptionProductType.BASE_SUBSCRIPTION);
        }
      })
      .filter((gpSubscriptionCard) => {
        const filteredMobileDevices = this.filters.get(FilterName.MOBILE_DEVICE) ?? [];
        if (filteredMobileDevices.length == 0) {
          return true;
        }
        return filteredMobileDevices.some((mobileDevice) =>
          gpSubscriptionCard.mobileDevices?.some((device) => device.deviceName === mobileDevice)
        );
      });
  }
}
