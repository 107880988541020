<div class="subscription-list-container">
  @switch (currentState) {
    @case (State.LOADED) {
      @for (subscriptionCard of gpSubscriptionCards; track subscriptionCard.subscriptionData.subscriptionUuid) {
        <ng-container>
          @if (!subscriptionCard.isExpired) {
            <fly-garmin-pilot-subscription-card
              [initialCardData]="subscriptionCard"
              [showAutoRenewToggle]="billingAccountId != null"
              [currentCart]="currentCart">
            </fly-garmin-pilot-subscription-card>
          }
          @if (subscriptionCard.isExpired) {
            <fly-garmin-pilot-subscription-card-expired
              [cardData]="subscriptionCard"
              [currentCart]="currentCart">
            </fly-garmin-pilot-subscription-card-expired>
          }
        </ng-container>
      }
    }
    @case (State.LOADING) {
      <g-loader
        class="loader"
        type="dark">
      </g-loader>
    }
    @case (State.ERROR) {
      <span>
        An error occurred while fetching the subscription data. Please refresh or contact
        <a routerLink="/aviation-support">Aviation Product Support</a>
        if the issue continues.
      </span>
    }
  }
</div>
