import { Injectable, inject } from '@angular/core';
import { DevicePlatformPipe } from '@garmin-avcloud/avcloud-fly-web-common/autorenewals';
import { Observable, map } from 'rxjs';
import { MobileDevice } from '../../../shared/models/mobile-device/mobile-device.model';
import { GpDeviceRegistrationsService } from '../../../shared/services/gp-device-registrations.service';

@Injectable({
  providedIn: 'root'
})
export class MobileDeviceListService {
  private readonly devicePlatformPipe = new DevicePlatformPipe();
  private readonly gpDeviceRegistrationsService = inject(GpDeviceRegistrationsService);

  getMobileDevices(): Observable<MobileDevice[]> {
    return this.gpDeviceRegistrationsService.getAllDeviceRegistrationsForCustomer().pipe(
      map((devices) => {
        const mobileDevices: MobileDevice[] = devices.map((device) => {
          return {
            name: device.gpDeviceName ?? 'Unknown Device',
            action: 'manage',
            site: 'FGA',
            uniqueId: device.uuid ?? null,
            devicePlatform: this.devicePlatformPipe.transform(device)
          } as MobileDevice;
        });

        return mobileDevices;
      })
    );
  }
}
