import { Component, computed, EventEmitter, inject, input, Output, ViewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { SubscriptionActionType } from '@garmin-avcloud/avcloud-fly-web-common/api';
import { FeatureFlyGarmin } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { FeaturesService } from '@garmin-avcloud/avcloud-web-utils/feature';
import { map } from 'rxjs';
import { SubscriptionActionTypeMap } from '../../../../shared/models/subscriptions/subscription-action-type-map';
import { SubscriptionCard } from '../../../../shared/models/subscriptions/subscription-card.model';
import { SubscriptionJobManagementService } from '../../../../shared/services/subscription-job-management.service';
import { BillingAccountService } from '../../services/billing-account.service';
import { AutoRenewalModalComponent } from '../auto-renewal-modal/auto-renewal-modal.component';

@Component({
  selector: 'fly-auto-renewal-toggle',
  templateUrl: './auto-renewal-toggle.component.html',
  styleUrls: ['./auto-renewal-toggle.component.scss'],
  standalone: false
})
export class AutoRenewalToggleComponent {
  private readonly featuresService = inject(FeaturesService);
  private readonly subscriptionJobManagementService = inject(SubscriptionJobManagementService);
  private readonly billingAccountService = inject(BillingAccountService);

  readonly promoCreditsTermExtensionsFeatureIsActive = toSignal(
    this.featuresService.isFeatureActive(FeatureFlyGarmin.PROMO_CREDITS_TERM_EXTENSIONS),
    {
      initialValue: false
    }
  );
  readonly toggleData = input.required<SubscriptionCard>();
  readonly pending = input.required<boolean>();

  @Output() readonly autoRenewalUpdateRequestSucceeded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() readonly subscriptionJobType: EventEmitter<SubscriptionActionType> =
    new EventEmitter<SubscriptionActionType>();
  @ViewChild(AutoRenewalModalComponent) modal: AutoRenewalModalComponent;
  readonly autorenewalEnabled = computed(() =>
    this.pending() ? null : (this.toggleData().subscriptionData.autorenewalEnabled ?? false)
  );
  readonly billingAccountId = computed(() => this.toggleData().subscriptionData.billingAccountId);
  readonly billingAccount = computed(() =>
    this.billingAccountService.getBillingAccountByBillingAccountId(this.billingAccountId())
  );

  private readonly pendingInitializeBillingAccountJobExists = toSignal(
    this.subscriptionJobManagementService
      .listenForJobsByActionType(SubscriptionActionTypeMap.INITIALIZE_BILLING_ACCOUNT.name)
      .pipe(map((jobs) => (jobs ?? []).length > 0)),
    { initialValue: true }
  );

  readonly toggleControl = computed(
    () =>
      new FormControl(
        {
          value: this.autorenewalEnabled() ?? false,
          disabled:
            (this.promoCreditsTermExtensionsFeatureIsActive() &&
              (this.billingAccount()?.isNonBillingAccount ?? true) &&
              this.pendingInitializeBillingAccountJobExists()) ||
            this.pending()
        },
        { nonNullable: true }
      )
  );

  confirmAutoRenewalToggle(event: Event): void {
    event.preventDefault();

    if (this.promoCreditsTermExtensionsFeatureIsActive()) {
      const billingAccount = this.billingAccount();
      if (billingAccount?.isNonBillingAccount === true) {
        if (this.billingAccountService.realBillingAccounts().length == 0) {
          console.log('Navigation event should fire');
        } else {
          this.modal.open();
        }
      } else {
        this.modal.open();
      }
    } else {
      this.modal.open();
    }
  }
}
