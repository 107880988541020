import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import {
  isGarminPilotSubscription,
  isAvdbSubscription,
  SubscriptionActionType
} from '@garmin-avcloud/avcloud-fly-web-common/api';
import { ModalComponent } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { concatMap, forkJoin, of } from 'rxjs';
import { UserConfig, UserConfigService } from 'src/app/core/services/config/user-config.service';
import { SubscriptionActionTypeMap } from '../../../../shared/models/subscriptions/subscription-action-type-map';
import { SubscriptionCard } from '../../../../shared/models/subscriptions/subscription-card.model';
import { SubscriptionJobTypeMap } from '../../../../shared/models/subscriptions/subscription-job-type-map';
import { PendingSubscriptionJobService } from '../../../../shared/services/pending-subscription-job.service';
import { SubscriptionsGeneralService } from '../../../../shared/services/subscriptions-general.service';

@Component({
  selector: 'fly-cancel-subscription-modal',
  templateUrl: './cancel-subscription-modal.component.html',
  standalone: false
})
export class CancelSubscriptionModalComponent {
  @Input({ required: true }) toggleData: SubscriptionCard;
  @Input({ required: true }) pending: boolean;
  @Input() deviceId: number | undefined;
  @Output() readonly autoRenewalUpdateRequestSucceeded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() readonly subscriptionJobType: EventEmitter<SubscriptionActionType> =
    new EventEmitter<SubscriptionActionType>();
  @ViewChild(ModalComponent) modal: ModalComponent;
  private userConfig: UserConfig | null = null;
  formControl = new FormControl({ value: false, disabled: false }, { nonNullable: true });

  constructor(
    private readonly subscriptionsGeneralService: SubscriptionsGeneralService,
    private readonly pendingSubscriptionJobService: PendingSubscriptionJobService,
    private readonly userConfigService: UserConfigService
  ) {
    this.userConfigService
      .getUserConfig()
      .pipe(takeUntilDestroyed())
      .subscribe((userConfig) => {
        this.userConfig = userConfig;
      });
  }

  open(): void {
    this.modal.open();
  }

  modalConfirm = (): void => {
    this.modal.close();

    this.formControl.disable();

    this.subscriptionsGeneralService
      .updateAutoRenew({
        transactionUuid: this.toggleData.transactionUuid,
        subscriptionUuid: this.toggleData.subscriptionData.subscriptionUuid,
        autoRenew: false
      })
      .pipe(
        concatMap((response) =>
          forkJoin({
            response: of(response),
            jobCreation: this.pendingSubscriptionJobService.createPendingSubscriptionJob({
              sku: this.toggleData.subscriptionData.sku,
              subscriptionJobType: isGarminPilotSubscription(this.toggleData.subscriptionData)
                ? SubscriptionJobTypeMap.PILOT
                : SubscriptionJobTypeMap.AVDB,
              subscriptionActionType: SubscriptionActionTypeMap.DISABLE_AUTO_RENEW,
              statusJobUuid: response.jobUuid,
              subscriptionUuid: this.toggleData.subscriptionData.subscriptionUuid,
              billingAccountId: this.toggleData.subscriptionData.billingAccountId,
              customerGuid: this.userConfig?.loggedInUserCustomerGuid ?? '',
              yarmouthDeviceId: this.deviceId?.toString(),
              masterAircraftUuid: isAvdbSubscription(this.toggleData.subscriptionData)
                ? (this.toggleData.subscriptionData.aircraftUuid ?? undefined)
                : undefined
            })
          })
        )
      )
      .subscribe({
        next: ({ response, jobCreation }) => {
          if (response.success) {
            this.subscriptionJobType.emit(jobCreation.subscriptionActionType);
            this.autoRenewalUpdateRequestSucceeded.emit(true);
            this.updateAutoRenewalStatus();
          } else {
            this.autoRenewalUpdateRequestSucceeded.emit(false);
          }
        },
        error: () => this.autoRenewalUpdateRequestSucceeded.emit(false)
      });
  };

  updateAutoRenewalStatus(): void {
    this.formControl.setValue(this.toggleData.subscriptionData.autorenewalEnabled ?? false, { emitEvent: false });
    if (this.pending) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }
}
