import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { of, switchMap } from 'rxjs';
import { Cart } from '../../../../shared/models/cart/cart.model';
import { GarminPilotSubscriptionCard } from '../../../../shared/models/subscriptions/garmin-pilot-subscription-card.model';
import { GpSubscriptionCardsFilterAndSortService } from '../../services/gp-subscription-cards-filter-and-sort.service';

enum State {
  LOADING,
  LOADED,
  ERROR
}

@Component({
  selector: 'fly-garmin-pilot-subscriptions-list',
  templateUrl: './garmin-pilot-subscriptions-list.component.html',
  styleUrls: ['./garmin-pilot-subscriptions-list.component.scss'],
  standalone: false
})
export class GarminPilotSubscriptionsListComponent implements OnInit {
  @Input() billingAccountId?: string;
  @Input({ required: true }) showOnlyExpired: boolean;
  @Input({ required: true }) gpSubscriptionCards: GarminPilotSubscriptionCard[];
  @Input() currentCart?: Cart;
  currentState: State = State.LOADING;
  readonly State = State;
  private readonly gpSubscriptionCardsFilterAndSortService = inject(GpSubscriptionCardsFilterAndSortService);
  private readonly destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    of(this.gpSubscriptionCards)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap((subscriptionCards) =>
          this.gpSubscriptionCardsFilterAndSortService.filterAndSortSubscriptionCards(subscriptionCards)
        )
      )
      .subscribe({
        next: (gpSubscriptionCards) => {
          this.gpSubscriptionCards = gpSubscriptionCards;
          this.currentState = State.LOADED;
        },
        error: () => (this.currentState = State.ERROR)
      });
  }
}
