<fly-modal
  header="Start Subscription Now?"
  [buttons]="
    isLoading
      ? []
      : [
          {
            text: 'Cancel',
            action: 'close'
          },
          {
            text: 'Start Now',
            primary: true,
            action: modalConfirm
          }
        ]
  ">
  <div slot="body">
    @if (!isLoading) {
      <p> Your subscription will start now and billing will occur per the terms of your subscription. </p>
      @if (errorMessage != null) {
        <g-error>{{ errorMessage }}</g-error>
      }
    } @else {
      <g-loader type="dark"></g-loader>
      Starting Subscription...
    }
  </div>
</fly-modal>
