import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  ClaimSubscriptionDeviceSlotRequest,
  GarminPilotSubscription,
  SubscriptionsControllerClient
} from '@garmin-avcloud/avcloud-fly-web-common/api';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CoreDataGpSubscriptionsClientService implements SubscriptionsControllerClient {
  private readonly http = inject(HttpClient);
  private readonly jsonHttpOptions = {
    headers: new HttpHeaders({
      Accept: 'application/json',
      'X-garmin-client-id': 'FLY_GARMIN_APP'
    })
  };
  private readonly url = (mode: 'Read' | 'Write'): string => {
    return `${environment.coreDataHostUrl}/subscription${mode}Service/subscriptions`;
  };

  getCustomerGpSubscriptions(customerGuid: string): Observable<GarminPilotSubscription[]> {
    let params = new HttpParams();

    if (customerGuid != null && customerGuid.trim().length > 0) {
      params = params.set('customerGuid', customerGuid);
    }

    return this.http.get<GarminPilotSubscription[]>(`${this.url('Read')}/customer/gp`, {
      ...this.jsonHttpOptions,
      params
    });
  }

  getCustomerGpSubscriptionsByDevice(deviceRegistrationUuid: string): Observable<GarminPilotSubscription[]> {
    let params = new HttpParams();

    if (deviceRegistrationUuid != null && deviceRegistrationUuid.trim().length > 0) {
      params = params.set('deviceRegistrationUuid', deviceRegistrationUuid);
    }
    return this.http.get<GarminPilotSubscription[]>(`${this.url('Read')}/customer/gp/by-device`, {
      ...this.jsonHttpOptions,
      params
    });
  }

  claimSubscriptionDeviceSlot(
    uuid: string,
    claimSubscriptionDeviceSlotRequest: ClaimSubscriptionDeviceSlotRequest
  ): Observable<null> {
    return this.http.post<null>(`${this.url('Write')}/${uuid}/device-slot`, claimSubscriptionDeviceSlotRequest, {
      ...this.jsonHttpOptions
    });
  }

  removeDeviceFromSubscriptionSlot(uuid: string, deviceRegistrationUuid: string): Observable<null> {
    let params = new HttpParams();
    if (deviceRegistrationUuid != null && deviceRegistrationUuid.trim().length > 0) {
      params = params.set('deviceRegistrationUuid', deviceRegistrationUuid);
    }

    return this.http.delete<null>(`${this.url('Write')}/${uuid}/device-slot`, {
      ...this.jsonHttpOptions,
      params
    });
  }
}
