import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { SubscriptionResponse, YarmouthSubscription } from '@garmin-avcloud/avcloud-fly-web-common/api';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UpdateAutoRenewRequest } from '../models/subscriptions/update-auto-renew-request.model';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionsGeneralService {
  private readonly http = inject(HttpClient);
  private readonly baseUrl = `${environment.flyLegacyUrl}/api/v2/subscriptions`;

  getSubscriptionByUuid(subscriptionUuid: string): Observable<SubscriptionResponse<YarmouthSubscription>> {
    return this.http.get<SubscriptionResponse<YarmouthSubscription>>(`${this.baseUrl}/${subscriptionUuid}`);
  }

  updateAutoRenew(payload: UpdateAutoRenewRequest): Observable<SubscriptionResponse> {
    return this.http.put<SubscriptionResponse>(`${this.baseUrl}/autorenew`, payload);
  }

  startSubscriptionNow(subscriptionUuid: string): Observable<SubscriptionResponse> {
    return this.http.put<SubscriptionResponse>(`${this.baseUrl}/${subscriptionUuid}/start-now`, null);
  }
}
