<fly-subscription-card>
  <ng-container alert>
    <fly-subscription-card-alert [showAddToCurrentCartError]="addToCurrentCartFailed"> </fly-subscription-card-alert>
  </ng-container>

  <ng-container firstContent>
    <span class="subscription-card--title">{{ cardData.product.display }}</span>
    @if (cardData.coveredUser != null) {
      <g-flex class="subscription-card--small-text subscription-card--field-value-text">
        <span class="subscription-card--bold-text no-wrap-text">Covered User:</span>
        <span class="no-wrap-text">{{ cardData.coveredUser }}</span>
      </g-flex>
    }
  </ng-container>

  <ng-container secondContent>
    @if (cardData.subscriptionData.endDate != null) {
      <g-flex class="subscription-card--small-text subscription-card--field-value-text">
        <span class="subscription-card--bold-text no-wrap-text">Expired:</span>
        <span class="no-wrap-text">{{ cardData.subscriptionData.endDate | flyFormattedDate }}</span>
      </g-flex>
    }
  </ng-container>

  <ng-container lastContent>
    <fly-add-to-cart-button
      [itemToAddToCurrentCart]="itemForAddingToCurrentCart"
      [presentInCurrentCart]="presentInCurrentCart"
      (addToCurrentCartFailed)="addToCurrentCartFailed = $event">
    </fly-add-to-cart-button>
  </ng-container>
</fly-subscription-card>
