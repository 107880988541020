// eslint-disable-next-line max-classes-per-file
import { SubscriptionActionType } from './subscription-action-type.model';

class Grant implements SubscriptionActionType {
  readonly uuid = '';
  readonly name = 'grant';
}

class Extend implements SubscriptionActionType {
  readonly uuid = '';
  readonly name = 'extend';
}

class Revoke implements SubscriptionActionType {
  readonly uuid = '';
  readonly name = 'revoke';
}

class Unredeem implements SubscriptionActionType {
  readonly uuid = '';
  readonly name = 'unredeem';
}

class EnableAutoRenew implements SubscriptionActionType {
  readonly uuid = '';
  readonly name = 'enable-auto-renew';
}

class DisableAutoRenew implements SubscriptionActionType {
  readonly uuid = '';
  readonly name = 'disable-auto-renew';
}

class Cancel implements SubscriptionActionType {
  readonly uuid = '';
  readonly name = 'cancel';
}

class StartNow implements SubscriptionActionType {
  readonly uuid = '';
  readonly name = 'start-now';
}

class UpdateStartDate implements SubscriptionActionType {
  readonly uuid = '';
  readonly name = 'update-start-date';
}

class UpdateDatabaseOptions implements SubscriptionActionType {
  readonly uuid = '';
  readonly name = 'update-database-options';
}

class InitializeBillingAccount implements SubscriptionActionType {
  readonly uuid = '';
  readonly name = 'initialize-billing-account';
}

export class SubscriptionActionTypeMap {
  static readonly GRANT: SubscriptionActionType = new Grant();
  static readonly EXTEND: SubscriptionActionType = new Extend();
  static readonly REVOKE: SubscriptionActionType = new Revoke();
  static readonly UNREDEEM: SubscriptionActionType = new Unredeem();
  static readonly ENABLE_AUTO_RENEW: SubscriptionActionType = new EnableAutoRenew();
  static readonly DISABLE_AUTO_RENEW: SubscriptionActionType = new DisableAutoRenew();
  static readonly CANCEL: SubscriptionActionType = new Cancel();
  static readonly START_NOW: SubscriptionActionType = new StartNow();
  static readonly UPDATE_START_DATE: SubscriptionActionType = new UpdateStartDate();
  static readonly UPDATE_DATABASE_OPTIONS: SubscriptionActionType = new UpdateDatabaseOptions();
  static readonly INITIALIZE_BILLING_ACCOUNT: SubscriptionActionType = new InitializeBillingAccount();
}
