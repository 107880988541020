import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { FeatureFlyGarmin } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { FeaturesService } from '@garmin-avcloud/avcloud-web-utils/feature';
import { CartAddItemRequest } from 'src/app/shared/models/cart/cart-add-item-request';
import { AvdbSubscriptionCard } from 'src/app/shared/models/subscriptions/avdb-subscription-card.model';
import { CartService } from 'src/app/shared/services/cart.service';
import { GarminPilotSubscriptionCard } from '../../../../shared/models/subscriptions/garmin-pilot-subscription-card.model';

@Component({
  selector: 'fly-renew-subscription-button',
  templateUrl: './renew-subscription-button.component.html',
  styleUrl: './renew-subscription-button.component.scss',
  standalone: false
})
export class RenewSubscriptionButtonComponent implements OnInit {
  @Input({ required: true }) itemToAddToCurrentCart: CartAddItemRequest;
  @Input({ required: true }) cardData: AvdbSubscriptionCard | GarminPilotSubscriptionCard;
  @Input() presentInCurrentCart: boolean;
  @Output() readonly addToCurrentCartFailed: EventEmitter<boolean> = new EventEmitter();
  private readonly cartService = inject(CartService);
  private readonly featuresService = inject(FeaturesService);
  private readonly router = inject(Router);
  private readonly destroyRef = inject(DestroyRef);
  addingToCurrentCart: boolean = false;
  disableButton: boolean = true;
  earlyRenewalEnabled: boolean = false;

  ngOnInit(): void {
    this.featuresService
      .isFeatureActive(FeatureFlyGarmin.SUBSCRIPTIONS_EARLY_RENEWAL)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((enabled) => (this.earlyRenewalEnabled = enabled));
  }

  addToCart(): void {
    this.addingToCurrentCart = true;

    this.cartService.addItemToCurrentCart(this.itemToAddToCurrentCart).subscribe({
      next: () => {
        this.addToCurrentCartFailed.emit(false);
        this.addingToCurrentCart = false;
        this.presentInCurrentCart = true;
      },
      error: () => {
        console.error('Error adding item to cart.');
        this.addToCurrentCartFailed.emit(true);
        this.addingToCurrentCart = false;
      }
    });
  }

  navigateToCartPage(): void {
    this.router.navigate(['/purchasing/cart']);
  }
}
