import { Component, computed, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { CartItemType } from '@garmin-avcloud/avcloud-fly-web-common/api';
import { SubscriptionSource } from '@garmin-avcloud/avcloud-fly-web-common/api/src/models/subscriptions-service/subscription-source.type';
import { SiteConfigService } from '@garmin-avcloud/avcloud-fly-web-common/config';
import { FeatureFlyGarmin } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { FeaturesService } from '@garmin-avcloud/avcloud-web-utils/feature';
import { catchError, map, of } from 'rxjs';
import { CartAddItemRequest } from '../../../../shared/models/cart/cart-add-item-request';
import { Cart } from '../../../../shared/models/cart/cart.model';
import { GarminPilotSubscriptionCard } from '../../../../shared/models/subscriptions/garmin-pilot-subscription-card.model';
import { DatesService } from '../../../../shared/services/dates.service';
import { SubscriptionCardAbstract } from '../../abstracts/subscription-card.abstract';
import { isFutureStartDate } from '../../utilities/is-future-start-date.utility';

@Component({
  selector: 'fly-garmin-pilot-subscription-card',
  templateUrl: './garmin-pilot-subscription-card.component.html',
  styleUrls: [
    '../subscription-card/subscription-card.component.scss',
    './garmin-pilot-subscription-card.component.scss'
  ],
  standalone: false
})
export class GarminPilotSubscriptionCardComponent extends SubscriptionCardAbstract implements OnInit {
  @Input({ required: true }) initialCardData: GarminPilotSubscriptionCard;
  @Input({ required: true }) override showAutoRenewToggle: boolean;
  @Input() currentCart?: Cart;

  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly dateService = inject(DatesService);
  private readonly router = inject(Router);
  private readonly featuresService = inject(FeaturesService);

  protected addToCurrentCartFailed: boolean | null = null;
  protected presentInCurrentCart: boolean = false;

  override cardData: GarminPilotSubscriptionCard;

  protected itemForAddingToCurrentCart: CartAddItemRequest;
  protected nonAutoRenewalSubscriptionExpiryReminderDays = toSignal(
    inject(SiteConfigService)
      .getSiteConfig()
      .pipe(
        map((siteConfig) => siteConfig.nonAutoRenewalSubscriptionExpiryReminderDays ?? 28),
        catchError(() => of(28)) // Default fallback value in case somehow site config explodes
      )
  );
  protected expiryIsApproaching = computed(() => {
    const days = this.nonAutoRenewalSubscriptionExpiryReminderDays();
    const endDate =
      this.initialCardData.subscriptionData.endDate == null
        ? null
        : this.dateService.parseDate(this.initialCardData.subscriptionData.endDate);
    if (this.initialCardData.subscriptionData.renewable != null || days == null || endDate == null) {
      return false;
    } else {
      const expiryReminderFirstDay = new Date(endDate.getTime());
      expiryReminderFirstDay.setDate(endDate.getDate() - days);
      const currentTime = new Date();
      return currentTime >= expiryReminderFirstDay && currentTime < endDate;
    }
  });

  yarmouthRenewalPriceEnabled: boolean = false;
  hasFutureStartDate: boolean = false;
  updateStartDateFailed: boolean = false;
  subSourceGrantTypes: SubscriptionSource[] = ['AVDB_GRANT', 'GP_GRANT'];

  ngOnInit(): void {
    this.cardData = this.initialCardData;
    this.createItemForAddingToCurrentCart();
    this.presentInCurrentCart =
      this.currentCart == null
        ? false
        : this.currentCart?.gpCart.some((item) => {
            const isRenewable =
              this.cardData.subscriptionData.belongsToBundle !== true &&
              this.cardData.subscriptionData.canRenewNow === true;
            return (
              item.product.partNumber === this.cardData.product.sku &&
              (!isRenewable || item.existingSubscriptionUuid === this.cardData.subscriptionData.subscriptionUuid)
            );
          });

    this.featuresService
      .isFeatureActive(FeatureFlyGarmin.SUBSCRIPTIONS_YARMOUTH_RENEWAL_PRICE)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((active) => {
        this.yarmouthRenewalPriceEnabled = active;
      });

    this.hasFutureStartDate = isFutureStartDate(this.cardData.subscriptionData.startDate);

    super.init();
  }

  navigateToManageMobileDevices(): void {
    const baseRoute =
      this.activatedRoute?.snapshot.data['isWebview'] === true ? '/webview/subscriptions' : '/subscriptions';
    this.router.navigate([
      `${baseRoute}/garmin-pilot/active-subscriptions/${this.cardData.subscriptionData.subscriptionUuid}`
    ]);
  }

  private createItemForAddingToCurrentCart(): void {
    this.itemForAddingToCurrentCart = {
      itemType: CartItemType.GARMIN_PILOT,
      partNumber: this.cardData.subscriptionData.sku,
      subscriptionID: undefined,
      deviceIDs: [],
      autoRenew: false,
      existingSubscriptionUuid:
        this.cardData.subscriptionData.belongsToBundle !== true && this.cardData.subscriptionData.canRenewNow === true
          ? this.cardData.subscriptionData.subscriptionUuid
          : undefined
    };
  }
}
