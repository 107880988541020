import { Pipe, PipeTransform } from '@angular/core';
import { isStringNonEmpty } from '@garmin-avcloud/avcloud-web-utils';

@Pipe({
  name: 'flyIsStringNonEmpty',
  standalone: false
})
export class IsStringNonEmptyPipe implements PipeTransform {
  transform(value: string): boolean {
    return isStringNonEmpty(value);
  }
}
