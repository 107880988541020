import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ComponentsGlobalWebModule } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { BillingAccount } from 'src/app/shared/models/subscriptions/billing-account.model';
import { SharedModule } from '../../../../../shared/shared.module';

@Component({
  selector: 'fly-billing-account-selection',
  templateUrl: './billing-account-selection.component.html',
  imports: [FormsModule, FormsModule, ReactiveFormsModule, ComponentsGlobalWebModule, SharedModule]
})
export class BillingAccountSelectionComponent implements OnInit {
  @Input() accounts: BillingAccount[] = [];
  @Input({ required: true }) formGroup: FormGroup;
  formControl: FormControl = new FormControl<string | null>(this.initialValue(), Validators.required);

  get isInvalid(): boolean {
    return this.formControl.invalid && (this.formControl.touched || this.formControl.dirty);
  }

  ngOnInit(): void {
    if (this.shouldShowDropdown()) {
      this.formControl.setValue(this.initialValue());
      this.formGroup.addControl('billingAccountSelect', this.formControl);
    }
  }

  initialValue(): string | null {
    if (this.formGroup?.controls?.billingAccountSelect?.value != null && this.shouldShowDropdown()) {
      return this.formGroup.controls.billingAccountSelect.value;
    } else if (!this.shouldShowPleaseSelectOption() && this.shouldShowDropdown()) {
      return this.accounts[0].billingAccountId;
    } else {
      return null;
    }
  }

  shouldShowPleaseSelectOption(): boolean {
    return this.accounts != null && this.accounts.length > 1;
  }

  shouldShowDropdown(): boolean {
    return this.accounts != null && this.accounts.length > 0;
  }

  selectBillingAccount(): void {
    // Update the form group value so external components providing the form group can get the new value
    const selectedBillingAccountId = this.formControl.value;
    if (this.formGroup?.controls?.billingAccountSelect != null) {
      this.formGroup.controls.billingAccountSelect.setValue(selectedBillingAccountId);
    }
  }
}
