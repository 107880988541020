<g-flex class="subscription-card">
  <ng-content select="[alert]"></ng-content>
  <g-flex class="subscription-card--content">
    <div class="subscription-card--first-content">
      <ng-content select="[firstContent]"></ng-content>
    </div>
    <div class="subscription-card--second-content">
      <ng-content select="[secondContent]"></ng-content>
    </div>
    <div class="subscription-card--last-content">
      <ng-content select="[lastContent]"></ng-content>
    </div>
  </g-flex>
</g-flex>
