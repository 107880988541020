import { Component, DestroyRef, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormControl, FormGroup, FormRecord } from '@angular/forms';
import { of, switchMap, take } from 'rxjs';
import { GpSubscriptionProductType } from '../../../../shared/enums/gp-subscription-type.enum';
import { GarminPilotSubscriptionCard } from '../../../../shared/models/subscriptions/garmin-pilot-subscription-card.model';
import { FilterName } from '../../enums/filter-name.enum';
import { SortOption } from '../../enums/sort-option.enum';
import { FilterOption } from '../../models/filter-name.model';
import { Filter } from '../../models/filter.model';
import { GpSubscriptionCardsFilterAndSortService } from '../../services/gp-subscription-cards-filter-and-sort.service';

enum State {
  LOADING,
  LOADED,
  ERROR
}

@Component({
  selector: 'fly-gp-subscriptions-filter-and-sort-menu',
  templateUrl: './gp-subscriptions-filter-and-sort-menu.component.html',
  styleUrls: ['../../../../../stylesheets/subscriptions-sort-and-filter-menu.scss'],
  standalone: false
})
export class GpSubscriptionsFilterAndSortMenuComponent implements OnInit, OnDestroy {
  @Input({ required: true }) gpSubscriptionCards: GarminPilotSubscriptionCard[];
  filters: Filter[] = [];
  currentState: State = State.LOADING;
  readonly formGroup = new FormGroup({
    sortOption: new FormControl<SortOption>(SortOption.DEFAULT, { nonNullable: true }),
    regionFilters: new FormRecord<FormControl<boolean>>({}),
    productTypeFilters: new FormRecord<FormControl<boolean>>({}),
    mobileDeviceFilters: new FormRecord<FormControl<boolean>>({})
  });
  readonly State = State;
  readonly SortOption = SortOption;
  readonly FilterName = FilterName;

  private readonly gpSubscriptionListFilterAndSortService = inject(GpSubscriptionCardsFilterAndSortService);

  private readonly destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.initializeFormSubscriptions();

    of(this.gpSubscriptionCards)
      .pipe(
        switchMap((cardData) => this.gpSubscriptionListFilterAndSortService.filterAndSortSubscriptionCards(cardData)),
        take(1)
      )
      .subscribe({
        next: (gpSubscriptionCards) => {
          this.initializeFilters(gpSubscriptionCards);
          this.initializeFormGroup();
          this.currentState = State.LOADED;
        },
        error: () => (this.currentState = State.ERROR)
      });
  }

  ngOnDestroy(): void {
    this.gpSubscriptionListFilterAndSortService.resetFiltersAndSorting();
  }

  private initializeFilters(gpSubscriptionCards: GarminPilotSubscriptionCard[]): void {
    this.filters = [
      {
        name: FilterName.REGION,
        options: this.generateRegionFilterOptions(gpSubscriptionCards)
      },
      {
        name: FilterName.PRODUCT_TYPE,
        options: this.generateProductTypeFilterOptions(gpSubscriptionCards)
      },
      {
        name: FilterName.MOBILE_DEVICE,
        options: this.generateMobileDeviceFilterOptions(gpSubscriptionCards)
      }
    ].filter((filter) => filter.options.length > 0);
  }

  private generateRegionFilterOptions(gpSubscriptionCards: GarminPilotSubscriptionCard[]): FilterOption[] {
    const regionCounts = new Map<string, number>();
    for (const gpSubscriptionCard of gpSubscriptionCards) {
      if (gpSubscriptionCard.product.regions != null) {
        for (const region of gpSubscriptionCard.product.regions) {
          regionCounts.set(region, (regionCounts.get(region) ?? 0) + 1);
        }
      }
    }

    const regionFilterOptions: FilterOption[] = [];
    regionCounts.forEach((count, region) => {
      regionFilterOptions.push({
        name: region,
        value: region,
        count: count
      });
    });

    return regionFilterOptions.sort((a, b) => a.name.localeCompare(b.name));
  }

  private generateProductTypeFilterOptions(gpSubscriptionCards: GarminPilotSubscriptionCard[]): FilterOption[] {
    const productTypeCounts = new Map<string, number>();
    for (const gpSubscriptionCard of gpSubscriptionCards) {
      if (gpSubscriptionCard.product.isAddon != null) {
        if (gpSubscriptionCard.product.isAddon) {
          productTypeCounts.set(
            GpSubscriptionProductType.ADD_ON,
            (productTypeCounts.get(GpSubscriptionProductType.ADD_ON) ?? 0) + 1
          );
        } else {
          productTypeCounts.set(
            GpSubscriptionProductType.BASE_SUBSCRIPTION,
            (productTypeCounts.get(GpSubscriptionProductType.BASE_SUBSCRIPTION) ?? 0) + 1
          );
        }
      }
    }

    const productTypeFilterOptions: FilterOption[] = [];
    productTypeCounts.forEach((count, productType) => {
      productTypeFilterOptions.push({
        name: productType,
        value: productType,
        count: count
      });
    });

    return productTypeFilterOptions.sort((a, b) => a.name.localeCompare(b.name));
  }

  private generateMobileDeviceFilterOptions(gpSubscriptionCards: GarminPilotSubscriptionCard[]): FilterOption[] {
    const mobileDeviceCounts = new Map<string, number>();
    for (const gpSubscriptionCard of gpSubscriptionCards) {
      if (gpSubscriptionCard.mobileDevices != null) {
        for (const mobileDevice of gpSubscriptionCard.mobileDevices) {
          mobileDeviceCounts.set(mobileDevice.deviceName, (mobileDeviceCounts.get(mobileDevice.deviceName) ?? 0) + 1);
        }
      }
    }

    const mobileDeviceFilterOptions: FilterOption[] = [];
    mobileDeviceCounts.forEach((count, mobileDeviceName) => {
      mobileDeviceFilterOptions.push({
        name: mobileDeviceName,
        value: mobileDeviceName,
        count: count
      });
    });

    return mobileDeviceFilterOptions.sort((a, b) => a.name.localeCompare(b.name));
  }

  private initializeFormSubscriptions(): void {
    this.subscribeToFormControlChanges(this.formGroup.controls.regionFilters, FilterName.REGION);
    this.subscribeToFormControlChanges(this.formGroup.controls.productTypeFilters, FilterName.PRODUCT_TYPE);
    this.subscribeToFormControlChanges(this.formGroup.controls.mobileDeviceFilters, FilterName.MOBILE_DEVICE);
    this.subscribeToSortOptionChanges(this.formGroup.controls.sortOption);
  }

  private subscribeToFormControlChanges(control: AbstractControl, filterName: FilterName): void {
    control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((filters) => {
      const selectedFilters = Object.keys(filters).filter((filter) => filters[filter]);
      this.gpSubscriptionListFilterAndSortService.updateFilter(filterName, selectedFilters);
    });
  }

  private subscribeToSortOptionChanges(control: AbstractControl): void {
    control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((sortOption) => {
      this.gpSubscriptionListFilterAndSortService.updateSortOption(sortOption);
    });
  }

  private initializeFormGroup(): void {
    this.addFilterToFormGroup(FilterName.REGION, this.formGroup.controls.regionFilters);
    this.addFilterToFormGroup(FilterName.PRODUCT_TYPE, this.formGroup.controls.productTypeFilters);
    this.addFilterToFormGroup(FilterName.MOBILE_DEVICE, this.formGroup.controls.mobileDeviceFilters);
  }

  private addFilterToFormGroup(filterName: FilterName, formGroup: FormRecord<FormControl<boolean>>): void {
    const existingFilter = this.filters.find((filter) => filter.name === filterName);
    if (existingFilter != null) {
      existingFilter.options.forEach((option) => {
        formGroup.addControl(option.value, new FormControl(false, { nonNullable: true }), { emitEvent: false });
      });
    }
  }
}
