import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { SubscriptionStatusResponse } from '../models/subscriptions/subscription-status-response.model';

@Injectable({
  providedIn: 'root'
})
export class ConnextClientService {
  private readonly httpClient = inject(HttpClient);
  private readonly url = `${environment.flyLegacyUrl}/api/connext`;

  getActivationSubscriptionJobStatus(jobUuid: string): Observable<SubscriptionStatusResponse> {
    return this.httpClient.get<SubscriptionStatusResponse>(`${this.url}/activation/subscriptions/${jobUuid}/status`);
  }
}
