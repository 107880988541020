import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { GarminPilotYarmouthSubscription, SubscriptionResponse } from '@garmin-avcloud/avcloud-fly-web-common/api';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { GPCouponRedemptionRequest } from '../models/garmin-pilot/gp-coupon-redemption-request.model';
import { GPCoupon } from '../models/garmin-pilot/gp-coupon.model';

@Injectable({
  providedIn: 'root'
})
export class YarmouthGpSubscriptionsService {
  private readonly httpClient = inject(HttpClient);
  private readonly baseUrl = `${environment.flyLegacyUrl}/api/v2/subscriptions/garmin-pilot`;

  getCoupon(couponCode: string): Observable<SubscriptionResponse<GPCoupon[]>> {
    if (couponCode.length < 1) {
      return throwError(() => new Error('Invalid coupon code is provided'));
    }

    return this.httpClient.get<SubscriptionResponse<GPCoupon[]>>(`${this.baseUrl}/coupons/${couponCode}`);
  }

  getGpSubscriptions(
    billingAccountId?: string,
    customerGuid?: string,
    includeExpired: boolean = false,
    includePrice: boolean = false,
    includeFuture: boolean = false
  ): Observable<SubscriptionResponse<GarminPilotYarmouthSubscription[]>> {
    if (
      ![billingAccountId, customerGuid].some((id) => id != null) ||
      [billingAccountId, customerGuid].every((id) => id != null)
    ) {
      return throwError(() => new Error('Only one of billingAccountId and customerGuid can be provided'));
    }

    let params = new HttpParams()
      .set('includeExpired', includeExpired)
      .set('includePrice', includePrice)
      .set('includeFuture', includeFuture);

    if (billingAccountId != null) {
      params = params.set('billingAccountId', billingAccountId);
    }

    if (customerGuid != null) {
      params = params.set('customerGuid', customerGuid);
    }

    return this.httpClient.get<SubscriptionResponse<GarminPilotYarmouthSubscription[]>>(`${this.baseUrl}`, { params });
  }

  redeemCoupon(
    requestBody: GPCouponRedemptionRequest
  ): Observable<SubscriptionResponse<GarminPilotYarmouthSubscription>> {
    return this.httpClient.post<SubscriptionResponse<GarminPilotYarmouthSubscription>>(
      `${this.baseUrl}/coupons/${requestBody.couponCode}/redeem`,
      requestBody
    );
  }
}
