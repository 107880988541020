import { Pipe, PipeTransform } from '@angular/core';
import { avdbPricingBundleFilterName, AvdbPricingBundleType } from '@garmin-avcloud/avcloud-fly-web-common/api';
import {
  GpSubscriptionRegion,
  gpSubscriptionRegionFilterName
} from '../../../shared/enums/gp-subscription-region.enum';
import {
  GpSubscriptionProductType,
  gpSubscriptionProductTypeFilterName
} from '../../../shared/enums/gp-subscription-type.enum';

@Pipe({
  name: 'flyFilterName',
  standalone: false
})
export class FilterNamePipe implements PipeTransform {
  transform(filterName: string): string {
    switch (filterName) {
      case AvdbPricingBundleType.DEVICE:
        return avdbPricingBundleFilterName.device;
      case AvdbPricingBundleType.ONEPAK:
        return avdbPricingBundleFilterName.onepak;
      case AvdbPricingBundleType.PILOTPAK:
        return avdbPricingBundleFilterName.pilotpak;
      case GpSubscriptionRegion.CANADA:
        return gpSubscriptionRegionFilterName.CA;
      case GpSubscriptionRegion.EUROPE:
        return gpSubscriptionRegionFilterName.EU;
      case GpSubscriptionRegion.UNITED_STATES:
        return gpSubscriptionRegionFilterName.US;
      case GpSubscriptionRegion.LATIN_AMERICA:
        return gpSubscriptionRegionFilterName.LA;
      case GpSubscriptionRegion.AFRICA:
        return gpSubscriptionRegionFilterName.AF;
      case GpSubscriptionRegion.ASIA:
        return gpSubscriptionRegionFilterName.AS;
      case GpSubscriptionRegion.AUSTRALIA:
        return gpSubscriptionRegionFilterName.AU;
      case GpSubscriptionRegion.NEW_ZEALAND:
        return gpSubscriptionRegionFilterName.NZ;
      case GpSubscriptionRegion.WORLD_WIDE:
        return gpSubscriptionRegionFilterName.WW;
      case GpSubscriptionProductType.BASE_SUBSCRIPTION:
        return gpSubscriptionProductTypeFilterName.baseSubscription;
      case GpSubscriptionProductType.ADD_ON:
        return gpSubscriptionProductTypeFilterName.addon;
      default:
        return filterName;
    }
  }
}
