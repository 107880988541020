import { inject, Injectable } from '@angular/core';
import { Aircraft, AircraftReadOnlyControllerService } from '@garmin-avcloud/avcloud-fly-web-common/api/aircraft/read';
import { AircraftSystem } from '@garmin-avcloud/avcloud-web-utils';
import { BehaviorSubject, catchError, forkJoin, map, Observable, of, shareReplay, switchMap, throwError } from 'rxjs';
import { take } from 'rxjs/operators';
import { sanitizeDateString } from 'src/app/shared/utilities/date-utilities';
import { environment } from '../../../../environments/environment';
import { Device } from '../../../shared/models/device/device.model';
import { AvdbSubscriptionCard } from '../../../shared/models/subscriptions/avdb-subscription-card.model';
import { AvdbSubscriptionsService } from '../../../shared/services/avdb-subscriptions.service';
import { DeviceService } from '../../../shared/services/device.service';
import { PendingSubscriptionJobService } from '../../../shared/services/pending-subscription-job.service';
import { SkuService } from '../../../shared/services/sku.service';
import { nullAndUndefinedFilter } from '../../../shared/utilities/null-and-undefined-filter';

@Injectable({
  providedIn: 'root'
})
export class AvdbSubscriptionListService {
  private readonly avdbSubscriptionCardDataSubject = new BehaviorSubject<AvdbSubscriptionCard[] | null>(null);
  private readonly avdbSubscriptionCardData$ = this.avdbSubscriptionCardDataSubject.asObservable().pipe(shareReplay(1));
  private selectedBillingAccountId: string;
  private readonly aircraftReadOnlyControllerService = inject(AircraftReadOnlyControllerService);
  private readonly avdbSubscriptionsService = inject(AvdbSubscriptionsService);
  private readonly pendingSubscriptionJobService = inject(PendingSubscriptionJobService);
  private readonly skuService = inject(SkuService);
  private readonly deviceService = inject(DeviceService);

  getAvdbSubscriptionCardData(
    billingAccountId: string,
    expiredOnly: boolean = false
  ): Observable<AvdbSubscriptionCard[] | null> {
    if (this.avdbSubscriptionCardData$ == null || billingAccountId !== this.selectedBillingAccountId) {
      this.avdbSubscriptionCardDataSubject.next(null);
      this.selectedBillingAccountId = billingAccountId;
      this.initializeAvdbSubscriptionCardData(billingAccountId).subscribe((avdbSubscriptionCards) =>
        this.avdbSubscriptionCardDataSubject.next(avdbSubscriptionCards)
      );
    }

    return this.avdbSubscriptionCardData$.pipe(
      map((cards) => (cards == null ? null : cards.filter((card) => card.isExpired === expiredOnly)))
    );
  }

  refreshAvdbSubscriptionCardData(): void {
    this.initializeAvdbSubscriptionCardData(this.selectedBillingAccountId)
      .pipe(take(1))
      .subscribe((newAvdbSubscriptionCardData) => {
        const cachedAvdbSubscriptionCardData = this.avdbSubscriptionCardDataSubject.value ?? [];
        const updatedAvdbSubscriptionCardData = this.mergeAvdbSubscriptionCardData(
          cachedAvdbSubscriptionCardData,
          newAvdbSubscriptionCardData
        );
        this.avdbSubscriptionCardDataSubject.next(updatedAvdbSubscriptionCardData);
      });
  }

  private mergeAvdbSubscriptionCardData(
    cachedAvdbSubscriptionCards: AvdbSubscriptionCard[],
    newAvdbSubscriptionCards: AvdbSubscriptionCard[]
  ): AvdbSubscriptionCard[] {
    const newAvdbSubscriptionCardsUuids = new Set(
      newAvdbSubscriptionCards.map((card) => card.subscriptionData.subscriptionUuid)
    );

    const existingAvdbSubscriptionCards = cachedAvdbSubscriptionCards
      .filter((existingCard) => newAvdbSubscriptionCardsUuids.has(existingCard.subscriptionData.subscriptionUuid))
      .map((existingCard) => {
        const updatedCard = newAvdbSubscriptionCards.find(
          (newCard) => newCard.subscriptionData.subscriptionUuid === existingCard.subscriptionData.subscriptionUuid
        );
        return updatedCard != null
          ? {
              ...existingCard,
              subscriptionData: updatedCard.subscriptionData,
              isExpired: updatedCard.isExpired,
              pendingJob: updatedCard.pendingJob,
              pendingJobAction: updatedCard.pendingJobAction
            }
          : existingCard;
      });

    const addedAvdbSubscriptionCards = newAvdbSubscriptionCards.filter(
      (card) =>
        !existingAvdbSubscriptionCards.some(
          (c) => c.subscriptionData.subscriptionUuid === card.subscriptionData.subscriptionUuid
        )
    );
    return [...addedAvdbSubscriptionCards, ...existingAvdbSubscriptionCards]; // New cards on top
  }

  private initializeAvdbSubscriptionCardData(billingAccountId: string): Observable<AvdbSubscriptionCard[]> {
    return this.avdbSubscriptionsService
      .getAvdbSubscriptions(billingAccountId, undefined, undefined, true, true, true)
      .pipe(
        switchMap((response) => {
          if (!response.success) {
            return throwError(() => new Error(response.errors.join(', ')));
          }

          // Don't bother making the other requests if there are no avdb subscriptions.
          if (response.data.length === 0) {
            return of(null);
          }

          // Remove duplicates and null values (yes these are actually master aircraft UUIDs)
          const masterAircraftUuidsSet = new Set(
            response.data.map((avdbSubscription) => avdbSubscription.aircraftUuid).filter(nullAndUndefinedFilter)
          );

          return forkJoin({
            aircraftList:
              masterAircraftUuidsSet.size > 0
                ? this.aircraftReadOnlyControllerService
                    .getAircraft(undefined, true) // includeMasterAircraftUuid = true
                    .pipe(
                      map((aircraftList) =>
                        aircraftList.filter(
                          (ac) => ac.masterAircraftUuid != null && masterAircraftUuidsSet.has(ac.masterAircraftUuid)
                        )
                      ),
                      catchError(() => of([] as Aircraft[]))
                    )
                : of([] as Aircraft[]),
            devices: response.data.some((avdbSubscription) => avdbSubscription.devices != null)
              ? this.deviceService.getDevices().pipe(catchError(() => []))
              : of([] as Device[]),
            avdbSubscriptionsResponse: of(response),
            pendingSubscriptions: this.pendingSubscriptionJobService.getPendingSubscriptionJobs({ billingAccountId }),
            products: this.skuService.getSkuProducts(
              response.data.map((avdbSubscription) => avdbSubscription.sku).filter(nullAndUndefinedFilter)
            )
          });
        }),
        map((data) => {
          // Don't bother doing any mapping if there are no avdb subscriptions.
          if (data == null) {
            return [];
          }

          const { aircraftList, devices, avdbSubscriptionsResponse, pendingSubscriptions, products } = data;

          return avdbSubscriptionsResponse.data.map((avdbSubscription) => {
            const tailNumber =
              aircraftList.find((aircraft) => {
                return aircraft.masterAircraftUuid === avdbSubscription.aircraftUuid;
              })?.tailNumber ?? null;

            const coveredDevice =
              devices.find((device) => {
                return avdbSubscription.devices != null && device.displaySerial === avdbSubscription.devices.at(0)?.id;
              }) ?? null;

            const coveredAircraftDevices =
              avdbSubscription.devices != null && avdbSubscription.devices.length > 0
                ? aircraftList
                    .flatMap((aircraft) => aircraft.systems)
                    .filter(
                      (system): system is AircraftSystem =>
                        system?.systemId != null &&
                        avdbSubscription.devices.some((device) => device.id === system.systemId)
                    )
                : null;

            avdbSubscription.endDate = sanitizeDateString(avdbSubscription.endDate);
            const endDate = new Date(avdbSubscription.endDate);
            const isExpired = !isNaN(endDate.getTime()) && new Date() > endDate;

            const pendingJob = isExpired
              ? null
              : pendingSubscriptions.find((pendingSubscription) => {
                  return pendingSubscription.subscriptionUuid === avdbSubscription.subscriptionUuid;
                });

            return {
              aircraftTailNumber: tailNumber,
              aircraftLinkUrl:
                tailNumber != null ? `${environment.flyLegacyUrl}/devices/aircraft/${tailNumber}` : tailNumber,
              deviceLinkUrl:
                tailNumber == null && coveredDevice != null
                  ? `${environment.flyLegacyUrl}/devices/details/${coveredDevice.id}`
                  : null,
              deviceName: tailNumber == null && coveredDevice != null ? coveredDevice.name : null,
              deviceSerial: tailNumber == null && coveredDevice != null ? coveredDevice.displaySerial : null,
              isExpired: isExpired,
              coveredAircraftSystems: coveredAircraftDevices,
              subscriptionData: avdbSubscription,
              pendingJob: pendingJob != null,
              pendingJobAction: pendingJob?.subscriptionActionType ?? null,
              product: products.find((product) => product.sku === avdbSubscription.sku),
              transactionUuid: avdbSubscriptionsResponse.transactionUuid
            } as AvdbSubscriptionCard;
          });
        })
      );
  }
}
