import { map, Observable, ReplaySubject } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { SubscriptionCard } from '../../../shared/models/subscriptions/subscription-card.model';
import { FilterName } from '../enums/filter-name.enum';
import { SortOption } from '../enums/sort-option.enum';

export abstract class SubscriptionCardsFilterAndSortServiceAbstract<T extends SubscriptionCard> {
  sortOption: SortOption = SortOption.DEFAULT;
  readonly filters: Map<FilterName, string[]> = new Map();
  readonly filterAndSortUpdates$: ReplaySubject<void> = new ReplaySubject(1);

  updateSortOption(sortOption: SortOption): void {
    this.sortOption = sortOption;
    this.filterAndSortUpdates$.next();
  }

  updateFilter(filterName: FilterName, filters: string[]): void {
    if (filters.length > 0) {
      this.filters.set(filterName, filters);
    } else {
      this.filters.delete(filterName);
    }

    this.filterAndSortUpdates$.next();
  }

  resetFiltersAndSorting(): void {
    this.filters.clear();
    this.sortOption = SortOption.DEFAULT;
  }

  filterAndSortSubscriptionCards(subscriptionCards: T[]): Observable<T[]> {
    // Whenever filtering/sorting options are changed, emit new subscription card data that is filtered/sorted accordingly
    return this.filterAndSortUpdates$.pipe(
      startWith(undefined),
      map(() => {
        const filteredSubscriptionCards = this.filterSubscriptionCards(subscriptionCards);
        return this.sortSubscriptionCards(filteredSubscriptionCards);
      })
    );
  }

  private sortSubscriptionCards(subscriptionCards: T[]): T[] {
    return subscriptionCards.sort((a, b) => {
      switch (this.sortOption) {
        case SortOption.EXPIRATION_DESC:
          return (
            new Date(b.subscriptionData?.endDate ?? 0).getTime() - new Date(a.subscriptionData?.endDate ?? 0).getTime()
          );
        case SortOption.NAME_ASC:
          return a.product.display.localeCompare(b.product.display);
        case SortOption.NAME_DESC:
          return b.product.display.localeCompare(a.product.display);
        default:
          return (
            new Date(a.subscriptionData?.endDate ?? 0).getTime() - new Date(b.subscriptionData?.endDate ?? 0).getTime()
          );
      }
    });
  }

  protected abstract filterSubscriptionCards(subscriptionCards: T[]): T[];
}
