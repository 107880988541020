//The date has been changing on us during testing in multiple ways which weren't anticipated.
//This will clean up the date field until it becomes stable.
export function sanitizeDateString(date?: string | null): string {
  let result = date;
  if (result == null || result === '') {
    return '';
  }
  if (result.toUpperCase().slice(-1) !== 'Z') {
    result = `${result}Z`;
  }

  const invalidSecondRegex = /\.\d\d\dZ$/;
  if (invalidSecondRegex.test(result)) {
    result = result.replace(invalidSecondRegex, 'Z');
  }

  return result;
}
