import { DestroyRef, inject, Injectable } from '@angular/core';
import { SubscriptionActionType } from '@garmin-avcloud/avcloud-fly-web-common/api';
import { SubscriptionCard } from '../../../shared/models/subscriptions/subscription-card.model';
import { SubscriptionJobManagementService } from '../../../shared/services/subscription-job-management.service';
import { AvdbSubscriptionListService } from '../services/avdb-subscription-list.service';

@Injectable()
export abstract class SubscriptionCardAbstract {
  cardData: SubscriptionCard;
  showAutoRenewalUpdateError: boolean = false;
  showAutoRenewToggle: boolean;
  readonly destroyRef = inject(DestroyRef);
  readonly subscriptionJobManagementService = inject(SubscriptionJobManagementService);
  readonly avdbSubscriptionListService = inject(AvdbSubscriptionListService);

  init(): void {
    this.updateAutorenewalEnability();
    if (this.showAutoRenewToggle) {
      this.subscriptionJobManagementService
        .listenForJobsBySubscriptionUuid(this.cardData.subscriptionData.subscriptionUuid, this.destroyRef)
        .subscribe((activeJob) => {
          if (activeJob != null) {
            this.cardData.pendingJob = true;
            this.cardData.pendingJobAction = activeJob.subscriptionActionType;
          } else if (
            this.cardData.pendingJob &&
            activeJob == null &&
            this.cardData.pendingJobAction?.name === 'revoke'
          ) {
            this.avdbSubscriptionListService.refreshAvdbSubscriptionCardData();
          } else {
            this.cardData.pendingJob = false;
            this.cardData.pendingJobAction = null;
          }

          this.updateAutorenewalEnability();
        });
    }
  }

  handleAutoRenewalUpdateResponse(requestSucceeded: boolean): void {
    if (!requestSucceeded) {
      this.cardData.pendingJob = false;
      this.showAutoRenewalUpdateError = true;
    } else {
      this.cardData.pendingJob = true;
      this.showAutoRenewalUpdateError = false;
      this.updateAutorenewalEnability();
    }
  }

  updateSubscriptionJobType(jobType: SubscriptionActionType): void {
    this.cardData.pendingJobAction = jobType;
  }

  updateAutorenewalEnability(): void {
    if (!this.cardData.pendingJob || this.cardData.pendingJobAction == null) {
      return;
    }
    switch (this.cardData.pendingJobAction.name) {
      case 'disable-auto-renew':
        this.cardData.subscriptionData.autorenewalEnabled = false;
        if (this.cardData.subscriptionData.endDate == null || this.cardData.subscriptionData.endDate === '') {
          this.cardData.subscriptionData.endDate = this.cardData.subscriptionData.nextBillDate;
          this.cardData.subscriptionData.nextBillDate = '';
        }
        break;
      case 'enable-auto-renew':
        this.cardData.subscriptionData.autorenewalEnabled = true;
        if (this.cardData.subscriptionData.nextBillDate == null || this.cardData.subscriptionData.nextBillDate === '') {
          this.cardData.subscriptionData.nextBillDate = this.cardData.subscriptionData.endDate ?? '';
          this.cardData.subscriptionData.endDate = null;
        }
        break;
      default:
        break;
    }
  }
}
