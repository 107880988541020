import { Injectable, inject } from '@angular/core';
import {
  ClaimSubscriptionDeviceSlotRequest,
  GarminPilotSubscription,
  SubscriptionsService
} from '@garmin-avcloud/avcloud-fly-web-common/api';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest, map, shareReplay, switchMap, tap } from 'rxjs';
import { CoreDataGpSubscriptionsClientService } from './coredata-gp-subscriptions-client.service';

@Injectable({
  providedIn: 'root'
})
export class CoreDataGpSubscriptionsService implements SubscriptionsService {
  private readonly coreDataGpSubscriptionsClient = inject(CoreDataGpSubscriptionsClientService);
  private readonly customerGuid$ = new ReplaySubject<string>();
  private readonly refresh$ = new BehaviorSubject<void>(undefined);
  private readonly gpSubscriptions$ = combineLatest({
    customerGuid: this.customerGuid$,
    refresh: this.refresh$
  }).pipe(
    switchMap(({ customerGuid }) => {
      return this.coreDataGpSubscriptionsClient.getCustomerGpSubscriptions(customerGuid);
    }),
    shareReplay(1)
  );

  initGpSubscriptions(customerGuid: string): Observable<GarminPilotSubscription[]> {
    this.customerGuid$.next(customerGuid);
    return this.gpSubscriptions$;
  }

  getGarminPilotSubscriptions(): Observable<GarminPilotSubscription[]> {
    return this.gpSubscriptions$;
  }

  getSubscriptionsByGpDeviceCode(gpDeviceCode: string): Observable<GarminPilotSubscription[]> {
    return this.gpSubscriptions$.pipe(
      map((gpSubscriptions) => {
        return gpSubscriptions.filter((subscription) => {
          return subscription.gpSubscriptions?.slotDetails.claimedSlotDevices?.includes(gpDeviceCode) ?? false;
        });
      })
    );
  }

  getSubscriptionByUuid(uuid: string): Observable<GarminPilotSubscription> {
    return this.gpSubscriptions$.pipe(
      map((gpSubscriptions: GarminPilotSubscription[]) => {
        const subscription = gpSubscriptions.find((gpSubscription) => gpSubscription.subscriptionUuid === uuid);
        if (subscription == null) {
          throw new Error(`No GP Subscription associated with subscriptionUuid: ${uuid}`);
        }
        return subscription;
      })
    );
  }

  claimSubscriptionDeviceSlot(
    uuid: string,
    claimSubscriptionDeviceSlotRequest: ClaimSubscriptionDeviceSlotRequest
  ): Observable<null> {
    return this.coreDataGpSubscriptionsClient
      .claimSubscriptionDeviceSlot(uuid, claimSubscriptionDeviceSlotRequest)
      .pipe(tap(() => this.refresh$.next()));
  }

  removeDeviceFromSubscriptionSlot(uuid: string, deviceRegistrationUuid: string): Observable<null> {
    return this.coreDataGpSubscriptionsClient
      .removeDeviceFromSubscriptionSlot(uuid, deviceRegistrationUuid)
      .pipe(tap(() => this.refresh$.next()));
  }
}
