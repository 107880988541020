<div class="mobile-devices-list--container">
  @switch (currentState) {
    @case (State.LOADED) {
      @if (mobileDevices.length === 0) {
        <p> There are no mobile devices added by the user. </p>
      }
      @if (mobileDevices.length > 0) {
        @if (!isWebview) {
          <h1 class="title"> Manage Mobile Devices </h1>
        }
        @for (deviceCard of mobileDevices; track deviceCard.uniqueId) {
          <fly-mobile-device-card
            [name]="deviceCard.name"
            [action]="deviceCard.action"
            [site]="deviceCard.site"
            [devicePlatform]="deviceCard.devicePlatform"
            (actionClicked)="navigateToDeviceDetails(deviceCard.uniqueId)">
          </fly-mobile-device-card>
        }
      }
    }
    @case (State.LOADING) {
      <fly-full-page-loader></fly-full-page-loader>
    }
    @case (State.ERROR) {
      <span>
        An error occurred while fetching the mobile device data. Please refresh or contact
        <a routerLink="/aviation-support">Aviation Product Support</a>
        if the issue continues.
      </span>
    }
  }
</div>
