import { Component, inject, Input, ViewChild } from '@angular/core';
import { SubscriptionResponse } from '@garmin-avcloud/avcloud-fly-web-common/api';
import { ComponentsGlobalWebModule, ModalComponent } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { SubscriptionsGeneralService } from '../../../../shared/services/subscriptions-general.service';

@Component({
  selector: 'fly-start-subscription-confirmation-modal',
  imports: [ComponentsGlobalWebModule, ModalComponent],
  templateUrl: './start-subscription-confirmation-modal.component.html'
})
export class StartSubscriptionConfirmationModalComponent {
  @Input({ required: true }) subscriptionUuid: string;
  @ViewChild(ModalComponent) modal: ModalComponent;
  errorMessage: string | null = null;
  isLoading = false;

  private readonly subscriptionsGeneralService = inject(SubscriptionsGeneralService);

  open(): void {
    this.modal.open();
  }

  readonly modalConfirm = (): void => {
    const errorMessage = 'There was an issue trying to start your subscription. Please Try again.';

    if (!this.isLoading) {
      this.isLoading = true;
      this.subscriptionsGeneralService.startSubscriptionNow(this.subscriptionUuid).subscribe({
        next: (response: SubscriptionResponse) => {
          if (response.errors == null) {
            this.errorMessage = null;
            this.modal.close();
          } else {
            console.error(response.errors);
            this.errorMessage = errorMessage;
          }
          this.isLoading = false;
        },
        error: (e) => {
          console.error(e);
          this.errorMessage = errorMessage;
          this.isLoading = false;
        }
      });
    }
  };
}
