import { inject, Injectable } from '@angular/core';
import {
  GarminPilotDeviceRegistration,
  GarminPilotSubscription as CoreDataSubscription,
  GarminPilotYarmouthSubscription
} from '@garmin-avcloud/avcloud-fly-web-common/api';
import { LocalStorageKey, LocalStorageService, OAuthTokenResponse } from '@garmin-avcloud/avcloud-web-utils';
import { combineLatest, forkJoin, map, Observable, of, shareReplay, switchMap, take, throwError } from 'rxjs';
import {
  GarminPilotSubscriptionCard,
  MobileDeviceIdentifier
} from '../../../shared/models/subscriptions/garmin-pilot-subscription-card.model';
import { CoreDataGpSubscriptionsService } from '../../../shared/services/coredata-gp-subscriptions.service';
import { GpDeviceRegistrationsService } from '../../../shared/services/gp-device-registrations.service';
import { MobileDeviceStatusService } from '../../../shared/services/mobile-device-status.service';
import { PendingSubscriptionJobService } from '../../../shared/services/pending-subscription-job.service';
import { SkuService } from '../../../shared/services/sku.service';
import { YarmouthGpSubscriptionsService } from '../../../shared/services/yarmouth-gp-subscriptions.service';
import { sanitizeDateString } from '../../../shared/utilities/date-utilities';
import { nullAndUndefinedFilter } from '../../../shared/utilities/null-and-undefined-filter';

@Injectable({
  providedIn: 'root'
})
export class GpSubscriptionListService {
  private gpSubscriptionCards$: Observable<GarminPilotSubscriptionCard[]> | null;
  private selectedBillingAccountId: string | undefined;
  private readonly coreDataGpSubscriptionsService = inject(CoreDataGpSubscriptionsService);
  private readonly deviceRegistrationsService = inject(GpDeviceRegistrationsService);
  private readonly gpSubscriptionsService = inject(YarmouthGpSubscriptionsService);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly pendingSubscriptionJobService = inject(PendingSubscriptionJobService);
  private readonly skuService = inject(SkuService);
  private readonly mobileDeviceStatusService = inject(MobileDeviceStatusService);

  getGpSubscriptionCards(
    billingAccountId?: string,
    expiredOnly: boolean = false
  ): Observable<GarminPilotSubscriptionCard[]> {
    if (
      this.gpSubscriptionCards$ == null ||
      billingAccountId !== this.selectedBillingAccountId ||
      this.mobileDeviceStatusService.mobileDevicesChanged()
    ) {
      this.selectedBillingAccountId = billingAccountId;
      const customerGuid =
        this.selectedBillingAccountId != null
          ? undefined
          : this.localStorageService.get<OAuthTokenResponse>(LocalStorageKey.OAuthTokenResponse)?.customerId;
      this.gpSubscriptionCards$ = this.initializeGpSubscriptionCardsData(billingAccountId, customerGuid);

      // Set to false so that we don't keep re-updating subscription cards after the mobile devices get updated
      this.mobileDeviceStatusService.updateMobileDeviceStatus(false);
    }

    return this.gpSubscriptionCards$.pipe(map((cards) => cards.filter((card) => card.isExpired === expiredOnly)));
  }

  private mapYarmouthGpSubscriptionsToDeviceModels(
    yarmouthSubscriptions: GarminPilotYarmouthSubscription[],
    coreDataSubscriptions: CoreDataSubscription[],
    deviceRegistrations: GarminPilotDeviceRegistration[]
  ): Map<string, MobileDeviceIdentifier[]> {
    // Map each GP subscription from Yarmouth to devices of a customer using CoreData subscriptions info
    const yarmouthSubscriptionDeviceModelsMap = new Map<string, MobileDeviceIdentifier[]>();
    for (const yarmouthSubscription of yarmouthSubscriptions) {
      const coveredDeviceIds = coreDataSubscriptions.reduce((foundDeviceIds, coreDataSubscription) => {
        const deviceIds = coreDataSubscription.gpSubscriptions?.slotDetails.claimedSlotDevices;
        return yarmouthSubscription.subscriptionUuid === coreDataSubscription.subscriptionUuid && deviceIds != null
          ? foundDeviceIds.concat(deviceIds)
          : foundDeviceIds;
      }, [] as string[]);

      const deviceModels = deviceRegistrations.map((deviceRegistration) => {
        return {
          deviceId: deviceRegistration.gpDeviceCode,
          deviceName: deviceRegistration.gpDeviceName ?? deviceRegistration.gpDeviceModel ?? 'Unknown',
          uuid: deviceRegistration.uuid
        } as MobileDeviceIdentifier;
      });

      const coveredDeviceModels = coveredDeviceIds.reduce((foundDeviceModels, coveredDeviceId) => {
        const foundDevices = deviceModels.filter((deviceModel) => deviceModel.deviceId === coveredDeviceId);
        return foundDeviceModels.concat(foundDevices);
      }, [] as MobileDeviceIdentifier[]);

      yarmouthSubscriptionDeviceModelsMap.set(yarmouthSubscription.subscriptionUuid, coveredDeviceModels);
    }

    return yarmouthSubscriptionDeviceModelsMap;
  }

  private initializeGpSubscriptionCardsData(
    billingAccountId?: string,
    customerGuid?: string
  ): Observable<GarminPilotSubscriptionCard[]> {
    // Get all active and expired subscriptions at the same time instead of separately to avoid fetching twice
    return this.gpSubscriptionsService.getGpSubscriptions(billingAccountId, customerGuid, true, true, true).pipe(
      switchMap((yarmouthGpSubscriptionsResponse) => {
        if (!yarmouthGpSubscriptionsResponse.success) {
          return throwError(() => new Error(yarmouthGpSubscriptionsResponse.errors.join(', ')));
        }

        // Don't bother making the other requests if there are no GP subscriptions.
        if (yarmouthGpSubscriptionsResponse.data.length === 0) {
          return of(null);
        }

        return forkJoin({
          gpSubscriptionsResponse: of(yarmouthGpSubscriptionsResponse),
          gpSubscriptionsDeviceModelsMap: combineLatest({
            yarmouthGpSubscriptions: of(yarmouthGpSubscriptionsResponse.data),
            coreDatGpSubscriptions: this.coreDataGpSubscriptionsService.getGarminPilotSubscriptions(),
            deviceRegistrations: this.deviceRegistrationsService.getAllDeviceRegistrationsForCustomer()
          }).pipe(
            take(1),
            map(({ yarmouthGpSubscriptions, coreDatGpSubscriptions, deviceRegistrations }) => {
              return this.mapYarmouthGpSubscriptionsToDeviceModels(
                yarmouthGpSubscriptions,
                coreDatGpSubscriptions,
                deviceRegistrations
              );
            })
          ),
          pendingSubscriptions:
            billingAccountId == null
              ? of([])
              : this.pendingSubscriptionJobService.getPendingSubscriptionJobs({ billingAccountId }),
          products: this.skuService.getSkuProducts(
            yarmouthGpSubscriptionsResponse.data
              .map((gpSubscription) => gpSubscription.sku)
              .filter(nullAndUndefinedFilter)
          )
        });
      }),
      map((data) => {
        // Don't bother doing any mapping if there are no GP subscriptions.
        if (data == null) {
          return [];
        }

        const { gpSubscriptionsResponse, gpSubscriptionsDeviceModelsMap, pendingSubscriptions, products } = data;

        // Skip subscriptions that could not be tied to a product
        const subscriptionsWithFoundProducts = gpSubscriptionsResponse.data.filter((gpSubscription) => {
          return products.find((product) => product.sku === gpSubscription.sku);
        });

        return subscriptionsWithFoundProducts.map((gpSubscription) => {
          gpSubscription.endDate = sanitizeDateString(gpSubscription.endDate);
          const endDate = new Date(gpSubscription.endDate);
          const isExpired = !(isNaN(endDate.getTime()) || endDate > new Date());
          const pendingJob = isExpired
            ? null
            : pendingSubscriptions.find((pendingSubscription) => {
                return pendingSubscription.subscriptionUuid === gpSubscription.subscriptionUuid;
              });
          const deviceModels = gpSubscriptionsDeviceModelsMap.get(gpSubscription.subscriptionUuid) ?? [];
          return {
            coveredUser: gpSubscription.customerEmail,
            mobileDevices: deviceModels,
            subscriptionData: gpSubscription,
            pendingJob: pendingJob != null,
            pendingJobAction: pendingJob?.subscriptionActionType ?? null,
            product: products.find((product) => product.sku === gpSubscription.sku),
            transactionUuid: gpSubscriptionsResponse.transactionUuid,
            isExpired
          } as GarminPilotSubscriptionCard;
        });
      }),
      shareReplay(1)
    );
  }
}
