import { Location } from '@angular/common';
import { Component, OnInit, WritableSignal, inject, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentState } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { Observable } from 'rxjs';
import { GarminPilotSubscriptionCard } from '../../../../shared/models/subscriptions/garmin-pilot-subscription-card.model';
import { GpDeviceRegistrationsService } from '../../../../shared/services/gp-device-registrations.service';

@Component({
  selector: 'fly-mobile-device-details',
  templateUrl: './mobile-device-details.component.html',
  styleUrls: ['./mobile-device-details.component.scss'],
  standalone: false
})
export class MobileDeviceDetailsComponent implements OnInit {
  deviceRegistrationUuid: string = '';
  gpSubscriptionCards$: Observable<GarminPilotSubscriptionCard[]>;
  protected currentState: WritableSignal<ComponentState> = signal('LOADING');
  protected location = inject(Location);
  private readonly gpDeviceRegistrationsService = inject(GpDeviceRegistrationsService);
  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);

  ngOnInit(): void {
    const uuid = this.route.snapshot.paramMap.get('uuid');
    if (uuid != null) {
      this.gpDeviceRegistrationsService.getDeviceRegistrationByUuid(uuid).subscribe({
        next: () => {
          this.deviceRegistrationUuid = uuid;
          this.currentState.set('LOADED');
        },
        error: () => {
          this.location.back();
        }
      });
    }
  }

  navigateToMobileDeviceList(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
