@if (pendingJob() && showPendingJobAlert()()) {
  <g-alert
    class="job-alert"
    type="info"
    slotAlign="center"
    (g-close)="showPendingJobAlert().set(false)">
    {{ cardMessage() }}
  </g-alert>
}
@if (showAutoRenewalUpdateError()) {
  <g-alert
    class="job-alert"
    type="error"
    slotAlign="center"
    (g-close)="showAutoRenewalUpdateError.set(false)">
    An error occurred that prevented the auto-renewal status from being modified.
  </g-alert>
}
@if (showAddToCurrentCartError()) {
  <g-alert
    class="job-alert"
    type="error"
    slotAlign="center"
    (g-close)="showAddToCurrentCartError.set(false)">
    An error occurred while adding this item.
  </g-alert>
}
