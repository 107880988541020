import { Injectable, signal } from '@angular/core';
import { MobileDeviceService } from '@garmin-avcloud/avcloud-fly-web-common/api';

@Injectable({
  providedIn: 'root'
})
export class MobileDeviceStatusService implements MobileDeviceService {
  readonly mobileDevicesChanged = signal<boolean>(false);

  updateMobileDeviceStatus(mobileDeviceStatus: boolean): void {
    this.mobileDevicesChanged.set(mobileDeviceStatus);
  }
}
