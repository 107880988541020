import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentState, Tab } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { combineLatest, filter, of, switchMap } from 'rxjs';
import { BillingAccountService } from 'src/app/features/subscription-management/services/billing-account.service';
import { GpSubscriptionListService } from 'src/app/features/subscription-management/services/gp-subscription-list.service';
import { Cart } from 'src/app/shared/models/cart/cart.model';
import { GarminPilotSubscriptionCard } from 'src/app/shared/models/subscriptions/garmin-pilot-subscription-card.model';
import { CartService } from 'src/app/shared/services/cart.service';
import { databasePurchaseUrl } from 'src/app/shared/utilities/urls';
import { RedeemPromoCodeModalComponent } from '../redeem-promo-code-modal/redeem-promo-code-modal.component';
import { TABS } from './gp-subscriptions.constants';

@Component({
  selector: 'fly-gp-subscriptions',
  templateUrl: './gp-subscriptions.component.html',
  styleUrl: './gp-subscriptions.component.scss',
  standalone: false
})
export class GarminPilotSubscriptionsComponent implements OnInit {
  @ViewChild('redeemPromoCode') redeemPromoCode: RedeemPromoCodeModalComponent;
  readonly billingAccountService: BillingAccountService = inject(BillingAccountService);
  readonly cartService: CartService = inject(CartService);
  private readonly gpSubscriptionListService = inject(GpSubscriptionListService);
  readonly catalogUrl = databasePurchaseUrl;
  private readonly activatedRoute = inject(ActivatedRoute);
  protected activeGpSubscriptionCards: GarminPilotSubscriptionCard[] = [];
  protected expiredGpSubscriptionCards: GarminPilotSubscriptionCard[] = [];
  protected currentState: ComponentState = 'LOADING';
  protected isWebview: boolean = false;
  readonly tabs: Tab[] = TABS;
  selectedTabRoute: string | string[] = this.tabs[0].route;
  billingAccountId: string | undefined;
  currentCart?: Cart;

  constructor(private readonly router: Router) {
    toObservable(this.billingAccountService.currentState)
      .pipe(
        filter((state) => state != 'LOADING'),
        switchMap((state) => {
          const billingAccount = this.billingAccountService.currentBillingAccount();
          this.billingAccountId = billingAccount?.billingAccountId;
          if (state === 'LOADED' && billingAccount != null) {
            const active = this.gpSubscriptionListService.getGpSubscriptionCards(
              billingAccount.billingAccountId,
              false
            );
            const expired = this.gpSubscriptionListService.getGpSubscriptionCards(
              billingAccount.billingAccountId,
              true
            );
            const currentCart = this.cartService.getCurrentCart();
            return combineLatest([active, expired, currentCart]);
          } else {
            return of([], [], []);
          }
        }),
        takeUntilDestroyed()
      )
      .subscribe({
        next: ([activeGpSubscriptionCards, expiredGpSubscriptionCards, currentCart]) => {
          if (this.billingAccountService.currentState() === 'ERROR') {
            this.currentState = 'ERROR';
          } else {
            this.activeGpSubscriptionCards = activeGpSubscriptionCards;
            this.expiredGpSubscriptionCards = expiredGpSubscriptionCards;
            this.currentCart = currentCart;
            this.currentState = 'LOADED';
          }
        },
        error: () => (this.currentState = 'ERROR')
      });
  }

  ngOnInit(): void {
    this.isWebview = this.activatedRoute.snapshot.data.isWebview;
    this.currentTab();
  }

  currentTab(): void {
    const currentPath = this.router.url;
    if (currentPath.includes('active-subscriptions')) {
      this.selectedTabRoute = this.tabs[0].route;
    } else if (currentPath.includes('expired-subscriptions')) {
      this.selectedTabRoute = this.tabs[1].route;
    } else if (currentPath.includes('manage-mobile-devices')) {
      this.selectedTabRoute = this.tabs[2].route;
    } else {
      this.selectedTabRoute = this.tabs[0].route;
    }
  }
}
