import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { PendingSubscriptionJob } from '@garmin-avcloud/avcloud-fly-web-common/api';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PendingSubscriptionJobQuery } from '../models/subscriptions/pending-subscription-job-query.model';

const PENDING_SUBSCRIPTION_API_URL = `${environment.flyLegacyUrl}/api/subscription-jobs-pending/`;

@Injectable({
  providedIn: 'root'
})
export class PendingSubscriptionJobService {
  private readonly httpClient: HttpClient = inject(HttpClient);

  createPendingSubscriptionJob(requestBody: Omit<PendingSubscriptionJob, 'id'>): Observable<PendingSubscriptionJob> {
    return this.httpClient.post<PendingSubscriptionJob>(PENDING_SUBSCRIPTION_API_URL, requestBody);
  }

  getPendingSubscriptionJobs(requestQuery: PendingSubscriptionJobQuery): Observable<PendingSubscriptionJob[]> {
    let params = new HttpParams();
    for (const [key, value] of Object.entries(requestQuery)) {
      if (value != null) {
        const paramValue = typeof value === 'string' ? value : value.name;
        params = params.set(key, paramValue);
      }
    }

    return this.httpClient.get<PendingSubscriptionJob[]>(PENDING_SUBSCRIPTION_API_URL, { params });
  }

  deletePendingSubscriptionsJob(pendingSubscriptionJobUuid: string): Observable<null> {
    return this.httpClient.delete<null>(`${PENDING_SUBSCRIPTION_API_URL}${pendingSubscriptionJobUuid}`);
  }
}
