export enum GpSubscriptionRegion {
  CANADA = 'CA',
  EUROPE = 'EU',
  UNITED_STATES = 'US',
  LATIN_AMERICA = 'LA',
  AFRICA = 'AF',
  ASIA = 'AS',
  AUSTRALIA = 'AU',
  NEW_ZEALAND = 'NZ',
  WORLD_WIDE = 'WW'
}

export const gpSubscriptionRegionFilterName: Record<GpSubscriptionRegion, string> = {
  [GpSubscriptionRegion.CANADA]: 'Canada',
  [GpSubscriptionRegion.EUROPE]: 'Europe',
  [GpSubscriptionRegion.UNITED_STATES]: 'United States',
  [GpSubscriptionRegion.LATIN_AMERICA]: 'Latin America',
  [GpSubscriptionRegion.AFRICA]: 'Africa',
  [GpSubscriptionRegion.ASIA]: 'Asia',
  [GpSubscriptionRegion.AUSTRALIA]: 'Australia',
  [GpSubscriptionRegion.NEW_ZEALAND]: 'New Zealand',
  [GpSubscriptionRegion.WORLD_WIDE]: 'World Wide'
};
