import { Injectable, inject } from '@angular/core';
import {
  GarminPilotDeviceRegistration,
  GarminPilotDeviceRegistrationsService
} from '@garmin-avcloud/avcloud-fly-web-common/api';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest, map, shareReplay, switchMap, tap } from 'rxjs';
import { GpDeviceRegistrationsClientService } from './gp-device-registrations-client.service';

@Injectable({
  providedIn: 'root'
})
export class GpDeviceRegistrationsService implements GarminPilotDeviceRegistrationsService {
  private readonly gpDeviceRegistrationsClient = inject(GpDeviceRegistrationsClientService);
  private readonly customerGuid$ = new ReplaySubject<string>();
  private readonly refresh$ = new BehaviorSubject<void>(undefined);
  private readonly gpDeviceRegistrations$ = combineLatest({
    customerGuid: this.customerGuid$,
    refresh: this.refresh$
  }).pipe(
    switchMap(({ customerGuid }) => {
      return this.gpDeviceRegistrationsClient.getGarminPilotDeviceRegistrations({ customerGuid });
    }),
    shareReplay(1)
  );

  initDeviceRegistrations(customerGuid: string): Observable<GarminPilotDeviceRegistration[]> {
    this.customerGuid$.next(customerGuid);
    return this.gpDeviceRegistrations$;
  }

  getDeviceRegistrationByUuid(uuid: string): Observable<GarminPilotDeviceRegistration> {
    return this.gpDeviceRegistrations$.pipe(
      map((deviceRegistrations) => {
        const deviceRegistration = deviceRegistrations.find((device) => device.uuid === uuid);
        if (deviceRegistration == null) {
          throw new Error(`Could not get Garmin Pilot device with id: ${uuid}.`);
        }

        return deviceRegistration;
      })
    );
  }

  getDeviceRegistrationByGpDeviceCode(gpDeviceCode: string): Observable<GarminPilotDeviceRegistration> {
    return this.gpDeviceRegistrations$.pipe(
      map((deviceRegistrations) => {
        const deviceRegistration = deviceRegistrations.find((device) => device.gpDeviceCode === gpDeviceCode);
        if (deviceRegistration == null) {
          throw new Error(`Could not get Garmin Pilot device with device code: ${gpDeviceCode}.`);
        }

        return deviceRegistration;
      })
    );
  }

  getAllDeviceRegistrationsForCustomer(): Observable<GarminPilotDeviceRegistration[]> {
    return this.gpDeviceRegistrations$.pipe(
      map((deviceRegistrations) => {
        return deviceRegistrations.sort((device1, device2) => {
          if (device1.gpDeviceName == null) {
            return 1;
          }

          if (device2.gpDeviceName == null) {
            return -1;
          }

          return device1.gpDeviceName.localeCompare(device2.gpDeviceName);
        });
      })
    );
  }

  deleteGarminPilotDeviceRegistration(uuid: string): Observable<null> {
    return this.gpDeviceRegistrationsClient.deleteGarminPilotDeviceRegistrations(uuid).pipe(
      tap(() => this.refresh$.next()),
      map(() => null)
    );
  }

  updateGarminPilotDeviceRegistration(
    uuid: string,
    deviceRegistration: Partial<GarminPilotDeviceRegistration>
  ): Observable<GarminPilotDeviceRegistration> {
    return this.gpDeviceRegistrationsClient.updateGarminPilotDeviceRegistrations(uuid, deviceRegistration);
  }
}
