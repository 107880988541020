<ng-template #loading>
  <fly-full-page-loader> </fly-full-page-loader>
</ng-template>

<ng-template #error>
  <span>
    An error occurred while fetching the subscription data. Please refresh or contact
    <a routerLink="/aviation-support">Aviation Product Support</a>
    if the issue continues.
  </span>
</ng-template>

<g-flex class="subpage-container">
  @if (!isWebview) {
    <fly-tabs
      [tabs]="tabs"
      class="tabs">
    </fly-tabs>
  }
  @if (currentState === 'LOADING') {
    <ng-container [ngTemplateOutlet]="loading"></ng-container>
  } @else if (currentState === 'ERROR') {
    <ng-container [ngTemplateOutlet]="error"></ng-container>
  } @else if (activeGpSubscriptionCards != null && currentState === 'LOADED') {
    <div class="subpage-container--main-content">
      @if (activeGpSubscriptionCards.length === 0) {
        <ng-container>
          <p>
            You do not have any active subscriptions to manage. To obtain Database subscriptions, visit the
            <a [href]="catalogUrl">database purchase</a>
            page to purchase new subscriptions.
          </p>
        </ng-container>
      }
      @if (activeGpSubscriptionCards.length > 0) {
        <ng-container>
          @if (!isWebview) {
            @if (selectedTabRoute === tabs[0].route) {
              <fly-gp-subscriptions-filter-and-sort-menu
                class="filter"
                [gpSubscriptionCards]="activeGpSubscriptionCards">
              </fly-gp-subscriptions-filter-and-sort-menu>
            } @else if (selectedTabRoute === tabs[1].route) {
              <fly-gp-subscriptions-filter-and-sort-menu
                class="filter"
                [gpSubscriptionCards]="expiredGpSubscriptionCards">
              </fly-gp-subscriptions-filter-and-sort-menu>
            }
            <hr />
            <div class="tab-list-container">
              @if (selectedTabRoute === tabs[0].route) {
                <fly-garmin-pilot-subscriptions-list
                  [billingAccountId]="billingAccountId"
                  [currentCart]="currentCart"
                  [gpSubscriptionCards]="activeGpSubscriptionCards"
                  [currentCart]="currentCart"
                  [showOnlyExpired]="false"
                  class="subscription-list">
                </fly-garmin-pilot-subscriptions-list>
              } @else if (selectedTabRoute === tabs[1].route) {
                <fly-garmin-pilot-subscriptions-list
                  [billingAccountId]="billingAccountId"
                  [currentCart]="currentCart"
                  [gpSubscriptionCards]="expiredGpSubscriptionCards"
                  [currentCart]="currentCart"
                  [showOnlyExpired]="true"
                  class="subscription-list">
                </fly-garmin-pilot-subscriptions-list>
              }
            </div>
          } @else {
            <fly-garmin-pilot-subscriptions-list
              [billingAccountId]="billingAccountId"
              [currentCart]="currentCart"
              [gpSubscriptionCards]="activeGpSubscriptionCards"
              [currentCart]="currentCart"
              [showOnlyExpired]="false"
              class="subscription-list">
            </fly-garmin-pilot-subscriptions-list>
          }
        </ng-container>
      }
    </div>
  }
</g-flex>
