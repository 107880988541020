import { Component, computed, input, model, signal } from '@angular/core';
import { SubscriptionActionType } from '@garmin-avcloud/avcloud-fly-web-common/api';

@Component({
  selector: 'fly-subscription-card-alert',
  templateUrl: './subscription-card-alert.component.html',
  styleUrls: ['./subscription-card-alert.component.scss'],
  standalone: false
})
export class SubscriptionCardAlertComponent {
  readonly pendingJobAction = input<SubscriptionActionType | null>();
  readonly pendingJob = input<boolean>(false);

  showAutoRenewalUpdateError = model(false);
  showAddToCurrentCartError = model<boolean | null>(false);

  protected readonly computedShowPendingJobAlert = computed(() => this.cardMessage() != '');
  protected readonly showPendingJobAlert = computed(() => signal(this.computedShowPendingJobAlert()));

  protected readonly cardMessage = computed(() => {
    if (!this.pendingJob() || this.pendingJobAction() == null) {
      return '';
    }
    switch (this.pendingJobAction()?.name) {
      case 'disable-auto-renew':
        return 'Subscription auto-renewal is being disabled';
      case 'enable-auto-renew':
        return 'Subscription auto-renewal is being enabled';
      case 'extend':
        return 'Subscription is being extended';
      case 'grant':
        return 'Subscription is being granted';
      case 'revoke':
        return 'Subscription is being revoked';
      case 'unredeem':
        return 'Subscription is being unredeemed';
      case 'redeem':
        return 'Subscription is being redeemed';
      case 'start-now':
        return 'Subscription is being started';
      case 'update-start-date':
        return 'Subscription start date is being updated';
      case 'update-database-options':
        return 'Subscription database options are being updated';
      default:
        return 'Subscription is pending';
    }
  });
}
