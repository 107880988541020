import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { LocalStorageKey, LocalStorageService, OAuthTokenResponse } from '@garmin-avcloud/avcloud-web-utils';
import { Observable, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BillingAccount } from '../models/subscriptions/billing-account.model';
import { Country } from '../models/subscriptions/country.model';
import { Invoice } from '../models/subscriptions/invoice.model';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  private readonly baseUrl = `${environment.flyLegacyUrl}/api/v2/accounts`;
  private readonly http = inject(HttpClient);
  private readonly localStorageService = inject(LocalStorageService);

  getAccounts(includeNonBillingAccountId: boolean = false): Observable<BillingAccount[]> {
    const customerGuid = this.localStorageService.get<OAuthTokenResponse>(
      LocalStorageKey.OAuthTokenResponse
    )?.customerId;

    if (customerGuid == null) {
      throw new Error('Unable to read customerGuid for authenticated user');
    }

    let params = new HttpParams().set('customerGuid', customerGuid);
    if (includeNonBillingAccountId) {
      params = params.set('includeNonBillingAccountId', includeNonBillingAccountId);
    }
    return this.http
      .get<BillingAccount[] | null>(this.baseUrl, { params })
      .pipe(map((billingAccounts) => billingAccounts?.filter((account) => account.billingAccountId != null) ?? []));
  }

  getInvoices(accountId: number): Observable<Invoice[]> {
    return this.http.get<Invoice[]>(`${this.baseUrl}/${encodeURIComponent(accountId)}/invoices`);
  }

  getInvoicePdf(accountId: number, invoiceId: number): Observable<Blob> {
    const mimeType = 'application/pdf';
    return this.http
      .get(`${this.baseUrl}/${encodeURIComponent(accountId)}/invoices/${encodeURIComponent(invoiceId)}/pdf`, {
        headers: new HttpHeaders({ Accept: mimeType }),
        responseType: 'blob' as 'json'
      })
      .pipe(map((pdfData) => new Blob([pdfData as BlobPart], { type: mimeType })));
  }

  getBillingCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(`${this.baseUrl}/eligible-countries`);
  }
}
