// eslint-disable-next-line max-classes-per-file
import { SubscriptionJobType } from '../../models/subscriptions/subscription-job-type.model';

class Avdb implements SubscriptionJobType {
  readonly uuid = '';
  readonly name = 'avdb';
}

class Pilot implements SubscriptionJobType {
  readonly uuid = '';
  readonly name = 'pilot';
}

class Connext implements SubscriptionJobType {
  readonly uuid = '';
  readonly name = 'connext';
}

export class SubscriptionJobTypeMap {
  static readonly AVDB: SubscriptionJobType = new Avdb();
  static readonly PILOT: SubscriptionJobType = new Pilot();
  static readonly CONNEXT: SubscriptionJobType = new Connext();
}
