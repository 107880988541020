import { AvdbSubscription } from '@garmin-avcloud/avcloud-fly-web-common/api';
import { AircraftSystem } from '@garmin-avcloud/avcloud-web-utils';
import { SubscriptionCard } from './subscription-card.model';

export interface AvdbSubscriptionCard extends SubscriptionCard {
  aircraftTailNumber: string | null;
  aircraftLinkUrl: string | null;
  deviceLinkUrl: string | null;
  deviceName: string | null;
  deviceSerial: string | null;
  isExpired: boolean;
  subscriptionData: AvdbSubscription;
  coveredAircraftSystems: AircraftSystem[] | null;
}

export const isAvdbSubscriptionCard = (val: any): val is AvdbSubscriptionCard => {
  if (val == null) {
    return false;
  }
  if (val.aircraftTailNumber != null) {
    return true;
  }
  if (val.aircraftLinkUrl != null) {
    return true;
  }
  if (val.deviceLinkUrl != null) {
    return true;
  }
  if (val.deviceName != null) {
    return true;
  }
  if (val.deviceSerial != null) {
    return true;
  }
  if (val.coveredAircraftSystems != null) {
    return true;
  }

  return false;
};
