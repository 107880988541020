<fly-modal
  #modal
  [header]="autorenewalEnabled() ? 'Disable Auto-Renewal?' : 'Enable Auto-Renewal?'"
  [buttons]="[
    {
      text: 'Cancel',
      action: 'close'
    },
    {
      text: autorenewalEnabled() ? 'Disable Auto-Renewal' : 'Enable Auto-Renewal',
      primary: true,
      action: modalConfirm,
      disabled: modalConfirmShouldDisable()
    }
  ]">
  @if (toggleData(); as toggleData) {
    <div
      slot="body"
      class="body">
      <div>
        @if (autorenewalEnabled()) {
          @if (toggleData.subscriptionData.endDate != null && toggleData.subscriptionData.endDate !== '') {
            <p>
              By disabling auto-renewal, you will no longer be charged automatically on
              <span class="no-wrap-text">{{ toggleData.subscriptionData.endDate | flyFormattedDate }}.</span>
            </p>
          }
          @if (toggleData.subscriptionData.endDate == null || toggleData.subscriptionData.endDate === '') {
            <p> By disabling auto-renewal, you will no longer be charged automatically. </p>
          }
        } @else {
          @if (toggleData.subscriptionData.endDate != null && toggleData.subscriptionData.endDate !== '') {
            <p>
              By enabling auto-renewal, you will be charged automatically on
              <span class="no-wrap-text">{{ toggleData.subscriptionData.endDate | flyFormattedDate }}</span>
              and annually until auto-renewal is disabled.
            </p>
          }
          @if (toggleData.subscriptionData.endDate == null || toggleData.subscriptionData.endDate === '') {
            <p> By enabling auto-renewal, you will be charged annually until auto-renewal is disabled. </p>
          }
        }
      </div>
      @if (promoCreditsTermExtensionsFeatureIsActive()) {
        <div>
          @if (originalBillingAccount()?.isNonBillingAccount === true) {
            @if (billingAccountService.realBillingAccounts().length === 1) {
              <div class="billing-account-selection">
                <p>The following billing account will be used when renewing this subscription:</p>
                <strong>{{ billingAccountService.realBillingAccounts()[0] | flyAccountDisplay }}</strong>
              </div>
            } @else {
              <div class="billing-account-selection">
                <p>To continue you must select the billing account to be used for renewal.</p>
                <fly-billing-account-selection
                  [accounts]="billingAccountService.realBillingAccounts()"
                  [formGroup]="formGroup">
                </fly-billing-account-selection>
              </div>
            }
          }
        </div>
      }
    </div>
  }
</fly-modal>
