import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CartAddItemRequest } from '../../../../shared/models/cart/cart-add-item-request';
import { CartService } from '../../../../shared/services/cart.service';

@Component({
  selector: 'fly-add-to-cart-button',
  templateUrl: './add-to-cart-button.component.html',
  styleUrl: './add-to-cart-button.component.scss',
  standalone: false
})
export class AddToCartButtonComponent {
  @Input({ required: true }) itemToAddToCurrentCart: CartAddItemRequest;
  @Input() presentInCurrentCart: boolean;
  @Output() readonly addToCurrentCartFailed: EventEmitter<boolean> = new EventEmitter();
  private readonly cartService = inject(CartService);
  private readonly router = inject(Router);
  addingToCurrentCart: boolean = false;

  addToCart(): void {
    this.addingToCurrentCart = true;

    this.cartService.addItemToCurrentCart(this.itemToAddToCurrentCart).subscribe({
      next: () => {
        this.addToCurrentCartFailed.next(false);
        this.addingToCurrentCart = false;
        this.presentInCurrentCart = true;
      },
      error: () => {
        this.addToCurrentCartFailed.next(true);
        this.addingToCurrentCart = false;
      }
    });
  }

  navigateToCartPage(): void {
    this.router.navigate(['/purchasing/cart']);
  }
}
