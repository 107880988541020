import { Component, Input, OnInit, inject } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { ObservableStatus } from '../../../../shared/enums/observable-status-enum';
import { GarminPilotSubscriptionCard } from '../../../../shared/models/subscriptions/garmin-pilot-subscription-card.model';
import { gpCatalogUrl } from '../../../../shared/utilities/urls';
import { BillingAccountService } from '../../services/billing-account.service';
import { GpSubscriptionListService } from '../../services/gp-subscription-list.service';

@Component({
  selector: 'fly-mobile-device-subscriptions-list',
  templateUrl: './mobile-device-subscriptions-list.component.html',
  styleUrls: ['./mobile-device-subscriptions-list.component.scss'],
  standalone: false
})
export class MobileDeviceSubscriptionsListComponent implements OnInit {
  @Input({ required: true }) deviceRegistrationUuid: string;
  readonly catalogUrl = gpCatalogUrl;
  protected readonly ObservableStatus = ObservableStatus;
  billingAccountId: string | undefined;
  gpSubscriptionCards$: Observable<GarminPilotSubscriptionCard[]>;
  protected readonly billingAccountService: BillingAccountService = inject(BillingAccountService);
  private readonly gpSubscriptionListService = inject(GpSubscriptionListService);

  ngOnInit(): void {
    this.billingAccountId = this.billingAccountService.currentBillingAccount()?.billingAccountId;
    this.gpSubscriptionCards$ = this.gpSubscriptionListService
      .getGpSubscriptionCards(this.billingAccountId, false)
      .pipe(
        switchMap((subscriptionCards) => {
          const cardsForDevice = subscriptionCards.filter((card) => {
            return (
              card.mobileDevices?.some((deviceIdentifier) => deviceIdentifier.uuid === this.deviceRegistrationUuid) ??
              false
            );
          });
          return of(cardsForDevice);
        })
      );
  }
}
