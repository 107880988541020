<h2>
  Subscriptions on this Mobile Device
  <hr />
</h2>
@if (gpSubscriptionCards$ | flyObservableStatus | async; as status) {
  @if (status.status === ObservableStatus.LOADING) {
    <ng-container [ngTemplateOutlet]="loading"></ng-container>
  }
  @if (status.status === ObservableStatus.ERROR) {
    <ng-container [ngTemplateOutlet]="error"></ng-container>
  }
  @if (status.status === ObservableStatus.LOADED && status.value != null) {
    <div class="subpage-container--main-content">
      @if (status.value.length === 0) {
        <p>
          You do not have any active subscriptions to manage. To manage Garmin Pilot subscriptions, visit the
          <a routerLink="/subscriptions/garmin-pilot">subscriptions page</a>
          or the
          <a [href]="catalogUrl">catalog</a>
          to purchase new subscriptions and add-ons.
        </p>
      }
      @if (status.value.length > 0) {
        <fly-garmin-pilot-subscriptions-list
          class="subscriptions-list"
          [gpSubscriptionCards]="status.value"
          [billingAccountId]="billingAccountService.currentBillingAccount()?.billingAccountId"
          [showOnlyExpired]="false">
        </fly-garmin-pilot-subscriptions-list>
      }
    </div>
  }
}

<ng-template #loading>
  <fly-full-page-loader> </fly-full-page-loader>
</ng-template>

<ng-template #error>
  <span>
    An error occurred while fetching the subscription data. Please refresh or contact
    <a routerLink="/aviation-support">Aviation Product Support</a>
    if the issue continues.
  </span>
</ng-template>
