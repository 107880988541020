import { computed, inject, Injectable, signal } from '@angular/core';
import { ComponentState } from '@garmin-avcloud/avcloud-fly-web-common/shared';
import { catchError, map, Observable, of } from 'rxjs';
import { BillingAccount } from '../../../shared/models/subscriptions/billing-account.model';
import { AccountsService } from '../../../shared/services/accounts.service';

@Injectable({
  providedIn: 'root'
})
export class BillingAccountService {
  private readonly accountsService: AccountsService = inject(AccountsService);
  readonly currentState = signal<ComponentState>('LOADING');
  readonly billingAccounts = signal<BillingAccount[] | null>(null);
  readonly currentBillingAccount = signal<BillingAccount | null>(null);
  readonly hasMultipleBillingAccounts = computed(() => (this.billingAccounts()?.length ?? 0) > 1);
  readonly realBillingAccounts = computed(() =>
    (this.billingAccounts() ?? []).filter((billingAccount) => billingAccount.isNonBillingAccount === false)
  );

  private readonly billingAccounts$: Observable<{ billingAccounts: BillingAccount[] | undefined; error: any }> =
    this.accountsService.getAccounts(true).pipe(
      map((billingAccounts) => ({ billingAccounts: billingAccounts, error: undefined })),
      catchError((error) => of({ billingAccounts: undefined, error: error }))
    );

  constructor() {
    this.getBillingAccounts();
  }

  private getBillingAccounts(): void {
    this.billingAccounts$.subscribe((data) => {
      if (data?.error != null) {
        this.currentState.set('ERROR');
      } else if (data.billingAccounts != null) {
        this.billingAccounts.set(data.billingAccounts);
        this.currentBillingAccount.set(data.billingAccounts.length > 0 ? data.billingAccounts[0] : null);
        this.currentState.set('LOADED');
      }
    });
  }

  getBillingAccountByBillingAccountId(billingAccountId: string): BillingAccount | undefined {
    return (this.billingAccounts() ?? []).find(
      (billingAccount) => billingAccount.billingAccountId === billingAccountId
    );
  }
}
