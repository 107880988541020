<fly-subscription-card>
  <ng-container alert>
    <fly-subscription-card-alert
      [pendingJobAction]="cardData.pendingJobAction"
      [pendingJob]="cardData.pendingJob"
      [(showAutoRenewalUpdateError)]="showAutoRenewalUpdateError"
      [(showAddToCurrentCartError)]="addToCurrentCartFailed">
    </fly-subscription-card-alert>
  </ng-container>

  <ng-container firstContent>
    <!-- The styling for the skeleton card is broken up into firstContent and lastContent
    because the contents of fly-subscription-card are projected into those ng-containers -->
    <span class="subscription-card--title">{{ cardData.product.display }}</span>

    <ng-template [ngTemplateOutlet]="startDate"></ng-template>

    @if (cardData.subscriptionData.endDate != null && cardData.subscriptionData.endDate !== '') {
      <div class="subscription-card--small-text subscription-card--field-value-text">
        <span class="subscription-card--bold-text no-wrap-text">Expiration Date:</span>
        <span class="no-wrap-text">{{ cardData.subscriptionData.endDate | flyFormattedDate }}</span>
      </div>
    }
    @if (cardData.coveredUser != null) {
      <div class="subscription-card--small-text subscription-card--field-value-text">
        <span class="subscription-card--bold-text no-wrap-text">Covered User:</span>
        <span class="no-wrap-text">{{ cardData.coveredUser }}</span>
      </div>
    }
    @if (cardData.subscriptionData.nextBillDate | flyIsStringNonEmpty) {
      <div class="subscription-card--field-value-text subscription-card--small-text">
        <span class="subscription-card--bold-text no-wrap-text">Plan Renews:</span>
        <span class="no-wrap-text">{{ cardData.subscriptionData.nextBillDate | flyFormattedDate }}</span>
      </div>
    }
    @if (cardData.mobileDevices != null && cardData.mobileDevices.length > 0) {
      <div class="subscription-card--small-text subscription-card--field-value-text">
        <span class="subscription-card--bold-text no-wrap-text">Mobile Devices:</span>
        <span>{{ cardData.mobileDevices | flyMobileDeviceNames }}</span>
      </div>
    }
    <div class="manage-mobile-device-button">
      <ng-template [ngTemplateOutlet]="manageMobileDeviceButton"></ng-template>
    </div>
    @if (
      cardData.subscriptionData.canRenewNow &&
      cardData.subscriptionData.renewalPrice != null &&
      cardData.subscriptionData.renewalPrice.amount > 0 &&
      yarmouthRenewalPriceEnabled
    ) {
      <div class="subscription-card--field-value-text subscription-card--small-text">
        <span class="subscription-card--bold-text no-wrap-text">Renewal Price:</span>
        <span class="no-wrap-text">{{
          cardData.subscriptionData.renewalPrice.amount | currency: cardData.subscriptionData.renewalPrice.currency
        }}</span>
      </div>
    }

    @if (hasFutureStartDate && updateStartDateFailed) {
      <div>
        <g-error class="error">
          An error occurred while updating the subscription's start date. Please try again, or contact
          <a routerLink="/aviation-support">Aviation Product Support</a> if the issue continues.
        </g-error>
      </div>
    }
  </ng-container>

  <div
    lastContent
    class="last-content--small-size-screen">
    @if (showAutoRenewToggle) {
      <ng-template [ngTemplateOutlet]="autoRenewalToggle"> </ng-template>
    }
    <ng-template [ngTemplateOutlet]="addToCartButton"></ng-template>
    <ng-template [ngTemplateOutlet]="renewSubscriptionButton"></ng-template>
    <ng-template [ngTemplateOutlet]="cancelSubscriptionButton"></ng-template>
  </div>

  <div
    lastContent
    class="last-content--normal-size-screen">
    @if (showAutoRenewToggle) {
      <ng-template [ngTemplateOutlet]="autoRenewalToggle"> </ng-template>
    }
    <ng-template [ngTemplateOutlet]="addToCartButton"></ng-template>
    <ng-template [ngTemplateOutlet]="renewSubscriptionButton"></ng-template>
    <ng-template [ngTemplateOutlet]="cancelSubscriptionButton"></ng-template>
  </div>
</fly-subscription-card>

<ng-template #manageMobileDeviceButton>
  <g-button
    class="subscription-card--button"
    size="medium"
    theme="light"
    type="button"
    (click)="navigateToManageMobileDevices()">
    MANAGE MOBILE DEVICES
  </g-button>
</ng-template>

<ng-template #autoRenewalToggle>
  <fly-auto-renewal-toggle
    [toggleData]="cardData"
    [pending]="cardData.pendingJob"
    (autoRenewalUpdateRequestSucceeded)="handleAutoRenewalUpdateResponse($event)"
    (subscriptionJobType)="updateSubscriptionJobType($event)">
  </fly-auto-renewal-toggle>
</ng-template>

<ng-template #addToCartButton>
  @if ((cardData.subscriptionData | flyIsGarminPilotSubscription) && expiryIsApproaching()) {
    @if ((cardData.subscriptionData | flyIsGarminPilotSubscription) && expiryIsApproaching()) {
      <fly-add-to-cart-button
        [presentInCurrentCart]="presentInCurrentCart"
        [itemToAddToCurrentCart]="itemForAddingToCurrentCart"
        (addToCurrentCartFailed)="addToCurrentCartFailed = $event">
      </fly-add-to-cart-button>
    }
  }
</ng-template>

<ng-template #renewSubscriptionButton>
  @if (!cardData.subscriptionData.belongsToBundle && cardData.subscriptionData.canRenewNow) {
    <fly-renew-subscription-button
      [cardData]="cardData"
      [presentInCurrentCart]="presentInCurrentCart"
      [itemToAddToCurrentCart]="itemForAddingToCurrentCart"
      (addToCurrentCartFailed)="addToCurrentCartFailed = $event">
    </fly-renew-subscription-button>
  }
</ng-template>

<ng-template #cancelSubscriptionButton>
  @if (cardData.subscriptionData.autorenewalEnabled && !cardData.subscriptionData.belongsToBundle) {
    <g-button
      theme="light"
      (click)="cancel.open()"
      type="button">
      CANCEL
    </g-button>
  }
</ng-template>

<ng-template #startDate>
  @if (hasFutureStartDate && !cardData.pendingJob) {
    @if (cardData.subscriptionData.startDate != null) {
      <div class="subscription-card--field-value-text subscription-card--small-text">
        <span class="subscription-card--bold-text no-wrap-text">Plan Starts:</span>
        <span class="no-wrap-text">{{ cardData.subscriptionData.startDate | flyFormattedDate }}</span>
        @if (
          !subSourceGrantTypes.includes(cardData.subscriptionData.source) && !cardData.subscriptionData.belongsToBundle
        ) {
          <button
            class="link-styled-button"
            (click)="startNow.open()">
            Start Now
          </button>
        }
      </div>
    }
  }
</ng-template>

<fly-cancel-subscription-modal
  #cancel
  [toggleData]="cardData"
  [pending]="cardData.pendingJob"
  (autoRenewalUpdateRequestSucceeded)="handleAutoRenewalUpdateResponse($event)"
  (subscriptionJobType)="updateSubscriptionJobType($event)">
</fly-cancel-subscription-modal>

<fly-start-subscription-confirmation-modal
  #startNow
  [subscriptionUuid]="cardData.subscriptionData.subscriptionUuid">
</fly-start-subscription-confirmation-modal>
