<g-flex class="toggle--base-wrapper">
  <span class="toggle--title">Auto-renew</span>
  @if (toggleData().subscriptionData.renewable && !toggleData().subscriptionData.belongsToBundle) {
    <g-switch
      leftLabel="Off"
      rightLabel="On">
      <!-- linthtml-disable input-req-label -->
      <input
        type="checkbox"
        [formControl]="toggleControl()"
        (click)="confirmAutoRenewalToggle($event)" />
      <!-- linthtml-enable input-req-label -->
    </g-switch>
  } @else {
    @if (toggleData().subscriptionData.belongsToBundle) {
      <span class="toggle--small-text">This subscription was granted as part of a database bundle purchase.</span>
    } @else {
      <span class="toggle--small-text">This subscription is not eligible for auto-renewal.</span>
    }
  }
</g-flex>

<fly-auto-renewal-modal
  #modal
  [autorenewalEnabled]="autorenewalEnabled()"
  [toggleData]="toggleData()"
  [toggleControl]="toggleControl()"
  (autoRenewalUpdateRequestSucceeded)="autoRenewalUpdateRequestSucceeded.emit($event)"
  (subscriptionJobType)="subscriptionJobType.emit($event)">
</fly-auto-renewal-modal>
